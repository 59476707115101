import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "governorate",
  pluralName: "governorates",
  pathName: "governorates",
  endpoint: "/admins/governorates",
  serverName: "governorate",
  serverPluralName: "governorates",
  displayName: { en: "Governorates", ar: "المحافظات" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  titleField: "name",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, masterDataOwner],
    edit: [superAdmin, applicationSupport, masterDataOwner],
    delete: [superAdmin, applicationSupport],
  },

  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم بالانجليزية" },
      accessor: "name_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم بالعربية" },
      accessor: "name_ar",
      inList: false,
      type: "string",
      required: true,
    },
  ],
});
