const statusesSection = [
  {
    identifier: "points_status",
    label: { en: "points status", ar: "حالة النقاط" },
    accessor: "points_status",
    lgCol: 6,
    mdCol: 6,
    type: "string",
    inList: true,
    disabled: true,
    hideInCreate: true,
    useHumanizer: true,
  },
  {
    identifier: "points_status_event",
    label: { en: "Points Status event", ar: "حالة النقاط" },
    accessor: (dataObject) => (dataObject.points_status_event ? dataObject.points_status_event.value : null),
    formAccessor: (dataObject) => (dataObject.points_status_event ? dataObject.points_status_event.value : null),
    lgCol: 12,
    mdCol: 12,
    hideInCreate: true,
    hideInShow: true,
    hideInEdit: true,
    type: "reference",
    optionsEntity: {
      name: "jobPointsStatusEvent",
      labelField: "label",
      valueField: "value",

      filterParams: [
        {
          name: "job_id",
          value: ["state", "id"],
          type: "edit",
        },
        {
          name: "state_column_name",
          value: "points_status",
          type: "edit",
        },
      ],
    },
  },
  {
    identifier: "reason",
    label: "Reason",
    accessor: "reason",
    type: "string",
    dependsOn: "points_status_event",
    dependsOnValue: ["cancel", "fully_cancel"],
    required: true,
  },
];

export default statusesSection;
