import login from "./login";
import forgetPassword from "./forgetPassword";
import sendNotifications from "./sendNotifications";
import resetPassword from "./resetPassword";
import completeProfile from "./completeProfile";
import inviteTechnician from "./invite_technician";
import inviteHomeowner from "./invite_homeowner";
import sap from "./sap";

const authConfig = {
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: "Admin",
  saveAuthHeadersOnce: true,
  enableAccountCreation: false,
};

const authUserDefinedEntities = [
  login,
  forgetPassword,
  resetPassword,
  completeProfile,
  sendNotifications,
  inviteTechnician,
  inviteHomeowner,
  sap,
];

export { authConfig, authUserDefinedEntities };
