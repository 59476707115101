import { Entity } from "@thewebops/admin-panel";
import statusesSection from "./sections/statuses";
import tablesSection from "./sections/tables";
import { ROLES } from "utils/constants";
import moment from "moment";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  accounant,
  afterSalesSectionHead,
} = ROLES;

export default new Entity({
  name: "maintenanceRequest",
  pluralName: "maintenanceRequests",
  pathName: "maintenance_requests",
  endpoint: "/admins/maintenance_requests",
  serverName: "maintenance_request",
  serverPluralName: "maintenance_requests",
  displayName: { en: "Maintenance Request", ar: "طلبات الصيانة" },
  allowCreate: false,
  allowEdit: true,
  allowDelete: false,
  showRowActions: true,
  titleField: "homeowner_id",
  authorizedRoles: {
    entity: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, accounant, afterSalesSectionHead],
    create: [superAdmin],
    edit: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, afterSalesSectionHead],
    delete: [superAdmin],
  },
  // titleField: "name",
  entityIdentifier: "id",
  rowActions: [
    {
      type: "formModal",
      label: "Update Status",
      authorizedRoles: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, afterSalesSectionHead],
      entityConfig: {
        name: "maintenanceRequest",
        fieldsOverrides: {
          status_event: {
            hideInEdit: false,
          },
        },
        referencedEntityField: "maintenance_request_id",
        entityOverrides: {
          customEditEndpoint: ({ entity, data, id }) => `${entity.endpoint}/${id}/update_status/`,
          customNotifications: {
            edit: {
              success: {
                title: "Edited",
                message: "Record Edited",
                isSticky: false,
              },
              error: {
                title: { en: "Fail", ar: "خطأ" },
                message: { en: "Cannot edit this request", ar: "لا يمكن تعديل هذا الطلب" },
                isSticky: false,
              },
            },
          },
        },
      },
      formAction: "edit",
    },
  ],
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
      required: true,
    },
    {
      identifier: "start_time",
      label: { en: "Start Time", ar: "وقت البدء" },
      formAccessor: "start_time",
      accessor: (dataObject) =>
        dataObject.start_time ? moment(dataObject.start_time).format("Do MMMM YYYY - h:mm a") : null,

      inList: true,
      type: "datetime",
    },
    {
      identifier: "end_time",
      label: { en: "End Time", ar: "وقت الانتهاء" },
      formAccessor: "end_time",
      accessor: (dataObject) =>
        dataObject.end_time ? moment(dataObject.end_time).format("Do MMMM YYYY - h:mm a") : null,
      inList: true,
      type: "datetime",
    },
    {
      identifier: "note",
      label: { en: "Note", ar: "ملاحظة" },
      accessor: "note",
      inList: false,
      type: "text",
      usedToShow: true,
      required: true,
    },
    ...statusesSection,
    {
      identifier: "product_sector_id",
      label: { en: "Product Sector", ar: "قطاع المنتج" },
      formAccessor: (dataObject) => dataObject.product_sector?.id,
      accessor: (dataObject) => dataObject.product_sector?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "productSector",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "homeowner_id",
      label: { en: "Homeowner", ar: "المالك" },
      formAccessor: (dataObject) => dataObject.homeowner?.id,
      accessor: (dataObject) => dataObject.homeowner?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "homeowner",
        labelField: "name",
        valueField: "id",
      },
      hideInEdit: true,
    },
    {
      identifier: "property_id",
      label: { en: "Property", ar: "منشأه" },
      formAccessor: (dataObject) => dataObject.property?.id,
      accessor: (dataObject) => dataObject.property?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "property",
        labelField: "name",
        valueField: "id",
      },
      hideInEdit: true,
    },
    {
      identifier: "date_of_request",
      accessor: (dataObject) =>
        dataObject.date_of_request ? moment(dataObject.date_of_request).format("Do MMMM YYYY - h:mm a") : null,
      label: { en: "Date Of Request", ar: "تاريخ الطلب" },
      type: "date",
      hideInCreate: true,
      hideInEdit: true,
      inList: true,
    },
    ...tablesSection,
  ],
  filters: [
    {
      identifier: "status",
      label: { en: "status", ar: "حالة" },
      name: "status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "requested", label: { en: "Requested", ar: "تم الابلاغ" } },
          { value: "scheduled", label: { en: "Scheduled", ar: "تم تحديد المعاد" } },
          { value: "canceled", label: { en: "Canceled", ar: "تم الغاء" } },
          { value: "done", label: { en: "Done", ar: "تم إنجازه" } },
        ],
      },
    },
  ],
});
