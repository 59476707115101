import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin, applicationSupport, masterDataOwner } = ROLES;
export default new Entity({
  name: "store",
  pluralName: "stores",
  pathName: "store",
  endpoint: "/admins/stores",
  serverName: "store",
  serverPluralName: "stores",
  displayName: { en: "Store", ar: "المتجر" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  titleField: "title",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [superAdmin, applicationSupport, masterDataOwner],
    create: [superAdmin, applicationSupport, masterDataOwner],
    edit: [superAdmin, applicationSupport, masterDataOwner],
    delete: [superAdmin, applicationSupport],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "title",
      label: { en: "Title", ar: "لقب" },
      accessor: "title",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "address",
      label: { en: "Address", ar: "عنوان" },
      accessor: "title",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "number_type",
      label: { en: "Number Type", ar: "الحالة" },
      accessor: "number_type",
      inList: true,
      type: "select",
      options: [
        { value: "home", label: "Home" },
        { value: "mobile", label: "Mobile" },
      ],
    },
    {
      identifier: "phone_number",
      label: { en: "Phone Number", ar: "رقم التليفون" },
      accessor: "phone_number",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "coords",
      accessor: "coords",
      label: { en: "Location", ar: "نقطة الإلتقاء" },
      type: "markerMap",
      mapConfig: {
        addressFieldIdentifier: "address_name",
        lngFieldIdentifier: "lng",
        latFieldIdentifier: "lat",
        governorateFieldIdentifier: "governorate",
      },
    },
  ],
});
