import { Entity } from "@thewebops/admin-panel";

export default (authConfig) =>
  new Entity({
    title: "SAP Integration",
    name: "sap",
    subTitle: "Send dopay response",
    path: "/sap",
    actionLabel: "Send response",
    showInSidebar: true,
    showInCollapsedSideBar: true,
    action: "create",
    fields: [
      {
        label: { en: "Batch Number", ar: "رقم الدفعة" },
        identifier: "batch_number",
        required: true,
        type: "string",
      },
      {
        identifier: "accepted_technicians",
        label: "Accepted Technicians",
        type: "reference",
        accessor: "accepted_technicians",
        multiple: true,
        isSearchable: true,
        optionsEntity: {
          name: "technician",
          labelField: "name",
          valueField: "do_pay_account_number",
          filterParams: [
            {
              name: "has_dopay_account",
              value: 'true',
              type: "create",
            },
          ],
        },
      },
      {
        identifier: "rejected_technicians",
        label: "Rejected Technicians",
        type: "reference",
        multiple: true,
        isSearchable: true,
        optionsEntity: {
          name: "technician",
          labelField: "name",
          valueField: "do_pay_account_number",
          filterParams: [
            {
              name: "has_dopay_account",
              value: 'true',
              type: "create",
            },
          ],
        },
      },
    ],
    requestConfig: {
      url: `admins/sap`,
      method: "post",
      authenticated: true,
      successMessage: "Response sent",
      errorMessage: "Couldn't send sap response",
    },
  });
