import { Entity } from "@thewebops/admin-panel";

export default (authConfig) =>
  new Entity({
    title: `Complete your ${authConfig.displayName} profile`,
    name: "completeProfile",
    subTitle: `Enter the credentials associated with your ${authConfig.displayName} account.`,
    path: "/confirmations/:token",
    actionLabel: "Confirm my Account",
    action: "create",
    fields: [
      {
        label: "New Password",
        required: true,
        type: "password",
        identifier: "password",
      },
      {
        label: "Confirm new Password",
        required: true,
        type: "password",
        identifier: "password_confirmation",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}/confirmation`,
      method: "post",
      successMessage: "Profile completed",
      errorMessage: "Couldn't complete profile",
      payloadWrapper: {
        name: "admin",
        fields: ["password", "password_confirmation", "confirmation_token"],
      },
      successRedirectPath: "/login",
      useFormData: true,
    },
    queryParamName: "confirmation_token",
  });
