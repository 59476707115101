import { Entity } from "@thewebops/admin-panel";

export default (authConfig) =>
  new Entity({
    title: { en: "Login to your account", ar: "تسجيل الدخول للحساب" },
    name: "login",
    subTitle: {
      en: `Enter the credentials associated with your ${authConfig.displayName} account.`,
      ar: `${authConfig.displayName}ادخل بيانات الحساب التابعة ل`,
    },
    logoTitle: "Hamza",
    path: "/login",
    actionLabel: { en: "Login", ar: "الدخول" },
    action: "create",
    // isFullScreen: true,
    submitButtonText: "Login",
    fields: [
      {
        label: { en: "Email", ar: "البريد الالكتروني" },
        required: true,
        type: "email",
        identifier: "email",
        accessor: "email",
      },
      {
        label: { en: "Password", ar: "كلمة المرور" },
        required: true,
        type: "password",
        identifier: "password",
        accessor: "password",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}/sign_in`,
      method: "post",
      saveToLocalStorage: true,
      useFormData: true,
    },
  });
