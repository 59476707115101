import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "orderStatusEvent",
  pluralName: "orderStatusEvents",
  pathName: "order_status_events",
  endpoint: "/admins/order_status_events",
  serverName: "event",
  serverPluralName: "events",
  displayName: "order status events",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  isPassive: true,
});
