import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  accounant,
} = ROLES;

export default new Entity({
  name: "client",
  pluralName: "clients",
  pathName: "client",
  endpoint: "/admins/clients",
  serverName: "client",
  serverPluralName: "clients",
  displayName: { en: "Clients", ar: "العملاء" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  titleField: "name",
  entityIdentifier: "id",
  customErrorKeys: ["retailer_distributors.retailer_distributors_attributes", "distributor should have only retailers"],
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      accounant,
    ],
    create: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, crSpecialist, crSectionHead],
    edit: [
      superAdmin,
      applicationSupport,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
    ],
    delete: [superAdmin, applicationSupport, crSpecialist, crSectionHead],
  },

  customNotifications: {
    delete: {
      error: {
        title: { en: "Not Allowed", ar: "غير مسموح" },
        message: (client) => client.base,
      },
    },
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "sap_id",
      label: { en: "SAP ID", ar: "الرقم التسلسلي لساب" },
      accessor: "sap_id",
      type: "number",
      required: true,
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم بالانجليزية" },
      accessor: "name_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم بالعربية" },
      accessor: "name_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "address",
      label: { en: "Address", ar: "عنوان" },
      accessor: "address",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "phone_number",
      label: { en: "Phone Number", ar: "رقم الهاتف" },
      accessor: "phone_number",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "client_type",
      label: { en: "Client type", ar: "نوع العميل" },
      accessor: "client_type",
      inList: true,
      required: true,
      type: "select",
      dependee: true,
      options: [
        { value: "project", label: { en: "Project", ar: "المشروع" } },
        { value: "distributor", label: { en: "Distributor", ar: "الموزع" } },
        { value: "retailer", label: { en: "Retailer", ar: "بائع تجزئة" } },
      ],
      disabled: (action) => action === "edit",      
    },
    {
      identifier: "distributor_id",
      label: "Distributor",
      accessor: (dataObject) => dataObject.distributor?.name,
      formAccessor: (dataObject) => dataObject.distributor?.id,
      type: "reference",
      isSearchable: true,
      dependsOn: 'client_type',
      dependsOnValue: "retailer",
      optionsEntity: {
        name: "client",
        labelField: (dataObject) => {
          if (dataObject.name) {
            return dataObject.name;
          } else {
            return dataObject.distributor.name;
          }
        },
        valueField: "id",
        valueLabel: "distributor_id",
        filterParams: [{ name: "client_type", value: "distributor", type: "both" }],
      },
      required: true
    },
    {
      identifier: "retailers",
      label: {en: "Retailers", ar: "تجار التجزئة"},
      accessor: "retailers",
      multiple: true,
      useObjectArray: true,
      type: "reference",
      isSearchable: true,
      optionsEntity: {
        labelField: "name",
      },
      dependsOn: "client_type",
      dependsOnValue: "distributor",
      hideInCreate: true,
      hideInEdit: true
    }
  ],
});
