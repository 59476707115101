import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "gift",
  pluralName: "gift",
  pathName: "gifts",
  endpoint: "/admins/gifts",
  serverName: "gift",
  serverPluralName: "gifts",
  displayName: { en: "Gifts", ar: "الهدايا" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  titleField: "name",
  entityIdentifier: "id",
  customErrorKeys: ["media_items"],
  authorizedRoles: {
    entity: [
      superAdmin,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, masterDataOwner],
    edit: [superAdmin, masterDataOwner],
    delete: [superAdmin],
  },

  customNotifications: {
    delete: {
      error: {
        title: { en: "Not Allowed", ar: "غير مسموح" },
        message: (dataObject) => dataObject.technician_gifts,
      },
    },
  },

  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
    },
    {
      identifier: "image",
      label: "Image",
      accessor: "image",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Logo", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: true,
    },
    {
      identifier: "gift_type",
      label: { en: "Gift type", ar: "نوع الهدية" },
      accessor: "gift_type",
      inList: true,
      hideInEdit: true,
      type: "select",
      options: [
        { value: "item", label: { en: "Item", ar: "الصنف" } },
        { value: "cash", label: { en: "Cash", ar: "نقدي" } },
        { value: "service", label: { en: "Service", ar: "خدمة" } },
      ],
    },
    {
      identifier: "currency",
      label: { en: "Currency", ar: "عملة" },
      accessor: "currency",
      inList: true,
      type: "select",
      options: [
        { value: "egp", label: { en: "EGP", ar: "جنيه مصري" } },
        { value: "usd", label: { en: "USD", ar: "دولار أمريكي" } },
        { value: "eur", label: { en: "EUR", ar: "يورو" } },
      ],
      dependsOn: "gift_type",
      dependsOnValue: ["cash"],
    },
    {
      identifier: "product_id",
      label: { en: "Product Article Number", ar: "الرقم التعريفي للمنتج" },
      formAccessor: (dataObject) => dataObject.product?.id,
      accessor: (dataObject) => dataObject.product?.article_number,
      inList: true,
      type: "reference",
      dependsOn: "gift_type",
      dependsOnValue: ["item"],
      isSearchable: true,
      optionsEntity: {
        name: "product",
        labelField: (dataObject) => {
          return `${dataObject.article_number} -- ${dataObject.name}`;
        },
        valueField: "id",
      },
    },
    {
      identifier: "description",
      label: { en: "Description", ar: "وصف" },
      accessor: "description",
      inList: false,
      type: "editor",
      dependsOn: "gift_type",
      dependsOnValue: ["service"],
      required: true,
    },
    {
      identifier: "sap_service_id",
      label: { en: "Sap Service ID", ar: "الرقم التعريفي للخدمة" },
      accessor: "sap_service_id",
      inList: false,
      type: "number",
      dependsOn: "gift_type",
      dependsOnValue: ["service"],
    },
    {
      identifier: "activation_status",
      label: { en: "Activation Status", ar: "حالة التفعيل" },
      accessor: "activation_status",
      inList: true,
      type: "select",
      options: [
        { value: true, label: { en: "active", ar: "مفعلة" } },
        { value: false, label: { en: "inactive", ar: "غير مفعلة" } },
      ],
      required: true,
    },
  ],
  filters: [
    {
      label: { en: "Gift type", ar: "نوع الهدية" },
      name: "gift_type",
      type: "select",
      multiple: true,
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "item", label: { en: "Item", ar: "الصنف" } },
          { value: "cash", label: { en: "Cash", ar: "نقدي" } },
          { value: "service", label: { en: "Service", ar: "خدمة" } },
        ],
      },
    },
    {
      label: { en: "Activation Status", ar: "حالة التفعيل" },
      name: "activation_status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: true, label: { en: "active", ar: "مفعلة" } },
          { value: false, label: { en: "inactive", ar: "غير مفعلة" } },
        ],
      },
    },
  ],
});
