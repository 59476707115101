import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "homeownerType",
  pluralName: "homeowner_types",
  pathName: "homeowner_types",
  endpoint: "/admins/homeowner_types",
  serverName: "homeowner_type",
  serverPluralName: "homeowner_types",
  displayName: { en: "Homeowner Types", ar: "نوع المالك" },
  titleField: "title",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, masterDataOwner],
    edit: [superAdmin, applicationSupport, masterDataOwner],
    delete: [superAdmin, applicationSupport],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "title",
      label: { en: "Title", ar: "الاسم" },
      accessor: "title",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "title_en",
      label: { en: "English Title", ar: "اللقب بالانجليزي" },
      accessor: "title_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "title_ar",
      label: { en: "Arabic Title", ar: "اللقب بالعربي" },
      accessor: "title_ar",
      type: "string",
      inList: false,
      required: true,
    },
  ],
});
