import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "property",
  pluralName: "properties",
  pathName: "property",
  endpoint: "/admins/properties",
  serverName: "property",
  serverPluralName: "properties",
  displayName: { en: "Properties", ar: "العقارات" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  showSearch: true,
  titleField: "name",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      crSpecialist,
      crSectionHead,
      masterDataOwner,
      accounant,
    ],
    create: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, crSectionHead, masterDataOwner],
    edit: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, crSectionHead, masterDataOwner],
    delete: [superAdmin, applicationSupport],
  },
  customNotifications: {
    delete: {
      success: {
        title: "Deleted",
        message: "Record Deleted",
        isSticky: true,
      },
      error: {
        title: { en: "Fail", ar: "خطأ" },
        message: "Record is associated with with scheduled maintenance requests or warranties",
        isSticky: true,
      },
    },
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "homeowner_id",
      label: { en: "Homeowner Name", ar: "إسم المالك" },
      formAccessor: (dataObject) => dataObject.homeowner?.id,
      accessor: (dataObject) => dataObject.homeowner?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "homeowner",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "living_area_id",
      label: { en: "Living Area", ar: "المنطقة السكنية" },
      formAccessor: (dataObject) => dataObject.living_area?.id,
      accessor: (dataObject) => dataObject.living_area?.name_en,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "livingArea",
        labelField: "name_en",
        valueField: "id",
      },
    },
    {
      identifier: "name",
      label: { en: "Property Name", ar: "إسم الملكية" },
      accessor: "name",
      type: "string",
      required: true,
      inList: true,
    },
    {
      identifier: "street_address",
      label: { en: "Street Address", ar: "إسم الشارع" },
      accessor: "street_address",
      type: "text",
      required: true,
      inList: true,
    },
    {
      identifier: "building_number",
      label: { en: "Building Number", ar: "رقم العقار" },
      accessor: "building_number",
      type: "number",
      required: true,
      inList: true,
    },
    {
      identifier: "floor_number",
      label: { en: "Floor Number", ar: "رقم الطابق" },
      accessor: "floor_number",
      type: "number",
      inList: true,
    },
    {
      identifier: "appartment_number",
      label: { en: "Apartment Number", ar: "رقم الشقة" },
      accessor: "appartment_number",
      type: "number",
      inList: true,
    },
    {
      identifier: "landmark",
      label: { en: "Landmark", ar: "علامة مميزة" },
      accessor: "landmark",
      type: "string",
      inList: true,
    },
    {
      identifier: "governorate_id",
      label: { en: "Governorate", ar: "المحافظة" },
      formAccessor: (dataObject) => dataObject.governorate?.id,
      accessor: (dataObject) => dataObject.governorate?.name_en,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "governorate",
        labelField: "name_en",
        valueField: "id",
      },
      hideInCreate: true,
      hideInEdit: true,
    },
  ],
  filters: [
    {
      label: { en: "Homeowner Name", ar: "إسم المالك" },
      name: "query",
      type: "searchInput",
      // fieldIdentifier: "name_en",
    },
    {
      label: { en: "Living Area", ar: "المنطقة السكنية" },
      name: "living_area_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "livingArea",
          labelField: "name_en",
          valueField: "id",
        },
      },
    },
  ],
});
