import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "zone",
  pluralName: "zones",
  pathName: "zone",
  endpoint: "/admins/zones",
  serverName: "zone",
  serverPluralName: "zones",
  displayName: { en: "Zones", ar: "المناطق" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  titleField: "name",
  entityIdentifier: "id",
  showSearch: true,
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, masterDataOwner],
    edit: [superAdmin, applicationSupport, masterDataOwner],
    delete: [superAdmin, applicationSupport, masterDataOwner, marketingOfficer],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم بالانجليزية" },
      accessor: "name_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم بالعربية" },
      accessor: "name_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "zone_agents_attributes",
      label: "Agents",
      accessor: "zone_agents",
      type: "reference",
      multiple: true,
      isSearchable: true,
      useObjectArray: true,
      optionsEntity: {
        name: "agent",
        labelField: "name",
        valueField: "id",
        valueLabel: "agent_id",
      },
      // required: true,
    },
    {
      identifier: "technicians",
      label: { en: "Technicians", ar: "الفنيين" },
      multiple: true,
      type: "reference",
      accessor: "technicians",
      optionsEntity: {
        name: "technician",
        labelField: "name",
        valueField: "id",
      },
      hideInCreate: true,
      hideInEdit: true,
    },
  ],
});
