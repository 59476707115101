import actions from "./actions";
console.log(actions.exportPointsTransactions);

const reducers = {
  [actions.exportPointsTransactions.request]: (state, action) => {
    state.loading = true;
  },
  [actions.exportPointsTransactions.success]: (state, action) => {
    state.loading = false;
  },
  [actions.exportPointsTransactions.failure]: (state, action) => {
    state.loading = false;
  },
};

export default reducers;
