import React from "react";
import { humanize } from "@thewebops/admin-panel";

export default ({ value }) => (
  <ul>
    {Object.keys(value).map(
      (key) =>
        key !== "job_id" && (
          <li>
            <strong>{humanize(key)}:</strong> {key.includes("_data") ? "changed" : value[key]}
          </li>
        ),
    )}
  </ul>
);
