import { Entity } from "@thewebops/admin-panel";
import actions from "./redux/actions";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import { ROLES } from "utils/constants";
import moment from "moment";

const { superAdmin, accounant } = ROLES;

export default new Entity({
  name: "pointsTransaction",
  pluralName: "pointsTransactions",
  pathName: "points_transactions",
  endpoint: "/admins/points_transactions",
  serverName: "points_transaction",
  serverPluralName: "points_transactions",
  displayName: { en: "Points Transactions", ar: "معاملات النقاط" },
  allowCreate: false,
  titleField: "personal_code",
  entityIdentifier: "id",
  allowEdit: false,
  allowDelete: false,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  customErrorKeys: ["points"],
  authorizedRoles: {
    entity: [superAdmin, accounant],
    create: [superAdmin, accounant],
    edit: [superAdmin],
    delete: [superAdmin],
  },

  globalActions: [
    {
      label: "Export",
      name: "exportPointsTransactions",
      authorizedRoles: [superAdmin, accounant],
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "points_type",
      label: { en: "Points Type", ar: "نوع النقاط" },
      accessor: "points_type",
      inList: true,
      type: "select",
      options: [
        { value: "withdraw", label: { en: "Withdraw", ar: "سحب" } },
        { value: "deposit", label: { en: "Deposit", ar: "ايداع" } },
      ],
      required: true,
    },
    {
      identifier: "number_of_points",
      label: { en: "number of points", ar: "عدد النفاط" },
      accessor: "number_of_points",
      inList: true,
      type: "number",
    },
    {
      identifier: "expiry_date",
      label: { en: "Expiration date", ar: "تاريخ الانتهاء" },
      accessor: (dataObject) => (dataObject.expiry_date ? moment(dataObject.expiry_date).format("MMMM YYYY") : null),
      inList: true,
      type: "datetime",
    },
    {
      identifier: "technician_id",
      label: { en: "Technician", ar: "الفني" },
      type: "reference",
      formAccessor: (dataObject) => dataObject.technician?.id,
      accessor: (dataObject) => dataObject.technician?.name,
      optionsEntity: {
        name: "technician",
        labelField: "name",
        valueField: "id",
      },
      inList: true,
      required: true,
    },
    {
      identifier: "personal_code",
      label: { en: "Technician Personal Code", ar: "كود الفني" },
      type: "reference",
      filterable: true,
      formAccessor: (dataObject) => dataObject.technician?.id,
      accessor: (dataObject) => dataObject.technician?.personal_code,
      optionsEntity: {
        name: "technician",
        labelField: "personal_code",
        valueField: "personal_code",
        displayAsLink: true,
      },
      inList: true,
    },
    {
      identifier: "consumed",
      label: { en: "Consumed", ar: "مستهلك" },
      accessor: "consumed",
      type: "select",
      options: [
        { value: true, label: { en: "Consumed", ar: "مستهلك" } },
        { value: false, label: { en: "Not consumed", ar: "غير مستهلك" } },
      ],
      inList: true,
      required: true,
    },
    {
      identifier: "point_event_id",
      label: { en: "Event Type", ar: "نوع الحدث" },
      accessor: (dataObject) => dataObject.point_event?.event_type,
      type: "select",
      options: [
        { value: "admin", label: { en: "Admin", ar: "مشرف" } },
        { value: "expire", label: { en: "Expire", ar: "انتهت" } },
        { value: "gift", label: { en: "Gift", ar: "هدية" } },
        { value: "job", label: { en: "Job", ar: "مهمة" } },
        { value: "bonus_points", label: { en: "Bonus Points", ar: "نقاط مكافأة" } },
      ],
      inList: true,
    },
  ],
  filters: [
    {
      label: { en: "Points Type", ar: "نوع النقاط" },
      name: "points_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "withdraw", label: { en: "Withdraw", ar: "سحب" } },
          { value: "deposit", label: { en: "Deposit", ar: "ايداع" } },
        ],
      },
    },
    {
      label: { en: "Consumed", ar: "مستهلك" },
      name: "consumed",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "true", label: { en: "Consumed", ar: "مستهلك" } },
          { value: "false", label: { en: "Not consumed", ar: "غير مستهلك" } },
        ],
      },
    },

    {
      label: { en: "Technician", ar: "الفني" },
      name: "technician_id",
      type: "select",
      multiple: true,
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "technician",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Event", ar: "الحدث" },
      name: "event_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "admin", label: { en: "Admin", ar: "مشرف" } },
          { value: "expire", label: { en: "Expire", ar: "انتهت" } },
          { value: "gift", label: { en: "Gift", ar: "هدية" } },
          { value: "job", label: { en: "Job", ar: "مهمة" } },
          { value: "bonus_points", label: { en: "Bonus Points", ar: "نقاط مكافأة" } },
        ],
      },
    },
    {
      label: { en: "Expiration date", ar: "تاريخ الانتهاء" },
      name: "expiry_date",
      type: "date",
      excludeDay: true,
      sendFullDate: true,
    },
  ],
});
