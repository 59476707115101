import { Entity } from "@thewebops/admin-panel";
import statusesSection from "./sections/statuses";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "warranty",
  pluralName: "warranties",
  pathName: "Warranties",
  endpoint: "/admins/warranties",
  serverName: "warranty",
  serverPluralName: "warranties",
  displayName: { en: "Warranties", ar: "الضمانات" },
  allowCreate: true,
  allowEdit: true,
  showRowActions: true,
  titleField: "job_id",
  authorizedRoles: {
    entity: [
      superAdmin,
      callCenterSupervisor,
      callCenterAgent,
      crSpecialist,
      crSectionHead,
      accounant,
      afterSalesSectionHead,
    ],
    create: [superAdmin, callCenterAgent, callCenterSupervisor],
    edit: [superAdmin, callCenterAgent, callCenterSupervisor],
    delete: [superAdmin, callCenterAgent, callCenterSupervisor],
  },
  rowActions: [
    {
      label: "Download Warranty",
      authorizedRoles: [superAdmin, afterSalesSectionHead, callCenterAgent, callCenterSupervisor],
      active: (dataObject) => dataObject && dataObject.e_warranty_url,
      customFunction: (dispatch, item) => {
        var win = window.open(item.e_warranty_url, "_blank");
        win.focus();
      },
    },
  ],
  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "homeowner_id",
      label: { en: "Homeowner", ar: "المالك" },
      formAccessor: (dataObject) => dataObject.homeowner?.id,
      accessor: (dataObject) => dataObject.homeowner?.phone_number,
      inList: true,
      type: "reference",
      required: true,
      isSearchable: true,
      hideInEdit: true,
      optionsEntity: {
        name: "homeowner",
        labelField: (dataObject) => {
          return `${dataObject.phone_number} -- ${dataObject.name}`;
        },
        valueField: "id",
      },
    },
    {
      identifier: "property_id",
      label: { en: "Property", ar: "العقار" },
      formAccessor: (dataObject) => dataObject.property?.id,
      accessor: (dataObject) => dataObject.property?.name,
      inList: true,
      type: "reference",
      required: true,
      isSearchable: true,
      hideInEdit: true,
      optionsEntity: {
        name: "property",
        labelField: "name",
        valueField: "id",
        filterParams: [{ name: "homeowner_id", value: ["state", "homeowner_id"], type: "both" }],
      },
    },
    {
      identifier: "job_id",
      label: { en: "Job Code", ar: "كود المهمة" },
      formAccessor: (dataObject) => dataObject.job?.id,
      accessor: (dataObject) => dataObject.job?.code,
      inList: true,
      isSearchable: true,
      hideInEdit: true,
      type: "reference",
      required: true,
      optionsEntity: {
        displayAsLink: true,
        name: "job",
        labelField: "code",
        valueField: "id",
        filterParams: [{ name: "homeowner_id", value: ["state", "homeowner_id"], type: "both" }],
      },
    },
    {
      identifier: "request_count",
      label: { en: "Requests Count", ar: "عدد مرات الطلب" },
      accessor: "request_count",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    ...statusesSection,
  ],

  filters: [
    {
      label: { en: "Job Code", ar: "المهمة" },
      name: "job_id",
      type: "select",
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "job",
          labelField: "code",
          valueField: "id",
        },
      },
    },
  ],
});
