const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV || "development") {
    case "development":
      return "http://localhost:3000/v1/";
    case "stg-dev":
      return "https://dev-hamza-api.thewebops.com/v1/";
    case "staging":
      return "https://hamza-api.thewebops.com/v1/";
    case "production":
      return "https://api.hamza.digital/v1/";
    default:
      return "https://hamza-api.thewebops.com/v1/";
  }
};

export const ROLES = {
  superAdmin: "super_admin",
  applicationSupport: "application_support",
  marketingOfficer: "marketing_officer",
  callCenterSupervisor: "call_center_supervisor",
  callCenterAgent: "call_center_agent",
  salesRep: "sales_rep",
  crSpecialist: "cr_specialist",
  crSectionHead: "cr_section_head",
  crWarehouse: "cr_warehouse",
  warehouseClerk: "warehouse_clerk",
  masterDataOwner: "master_data_owner",
  accounant: "accounant",
  purchaser: "purchaser",
  afterSalesSectionHead: "after_sales_section_head",
  technicalSales: "technical_sales",
  hr: "hr",
};

export const API_BASE_URL = getBaseUrl();
