import { Entity } from "@thewebops/admin-panel";
import GiftTiersMatrix from "../../components/GiftTiersMatrix";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "PointTierGift",
  pluralName: "PointTierGifts",
  pathName: "points_tier_gifts",
  endpoint: "/admins/points_tier_gifts",
  serverName: "points_tier_gift",
  serverPluralName: "points_tier_gifts",
  displayName: { en: "Point Tier Gifts", ar: "هدايا مستويات النقاط" },
  allowCreate: false,
  useFormData: true,
  authorizedRoles: {
    entity: [
      superAdmin,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin],
    edit: [superAdmin, masterDataOwner],
    delete: [superAdmin],
  },
  customListComponent: GiftTiersMatrix,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
});
