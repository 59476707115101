import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  technicalSales,
  afterSalesSectionHead,
} = ROLES;

export default new Entity({
  name: "blog",
  pluralName: "blogs",
  pathName: "blog",
  endpoint: "/admins/blogs",
  serverName: "blog",
  serverPluralName: "blogs",
  displayName: { en: "Blog", ar: "مدونة" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  titleField: "title",
  customErrorKeys: ["media_items"],
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      technicalSales,
      afterSalesSectionHead,
    ],
    create: [superAdmin, applicationSupport, marketingOfficer],
    edit: [superAdmin, applicationSupport, marketingOfficer],
    delete: [superAdmin, applicationSupport, marketingOfficer],
  },
  entityIdentifier: "id",
  fields: [
    {
      identifier: "seo_featured_image",
      label: "Seo Featured Image",
      accessor: "seo_featured_image",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "image", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },

        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: false,
    },
    {
      identifier: "blog_category_id",
      label: { en: "Blog Category", ar: "فئة المدونة" },
      formAccessor: (dataObject) => dataObject.blog_category?.id,
      accessor: (dataObject) => dataObject.blog_category?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "blogCategory",
        labelField: "name",
        valueField: "id",
      },
    },
      {
        identifier: "image",
        label: "Image",
        accessor: "image",
        type: "dynamic",
        preventMultiple: true,
        fields: [
          {
            identifier: "type",
            label: "type",
            accessor: "content_type",
            value: "image",
          },
          {
            useGallery: true,
            identifier: "file",
            label: { en: "image", ar: "الصورة" },
            type: "image",
            accessor: "file",
            mediaOptions: {},
          },
          {
            identifier: "media_id",
            label: "media",
            accessor: "media_id",
            hideInShow: true,
          },
          {
            identifier: "content_type",
            label: "content_type",
            accessor: "content_type",
            hideInShow: true,
          },
        ],
        required: true,
      },
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "title",
      label: { en: "Title", ar: "الاسم" },
      accessor: "title",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "title_en",
      label: { en: "English Title", ar: "العنوان بالانجليزية" },
      accessor: "title_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "title_ar",
      label: { en: "Arabic Title", ar: "العنوان بالعربية" },
      accessor: "title_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "title_de",
      label: { en: "German Title", ar: "العنوان بالالمانية" },
      accessor: "title_de",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "text_en",
      label: { en: "English Detailed Text", ar: "نص مفصل بالإنجليزية" },
      accessor: "text_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "text_ar",
      label: { en: "Arabic Detailed Text", ar: "نص مفصل بالعربية" },
      accessor: "text_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "text_de",
      label: { en: "German Detailed Text", ar: "نص مفصل بالالماني" },
      accessor: "text_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_en",
      label: { en: "English Brief", ar: "موجز إنجليزي" },
      accessor: "description_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_ar",
      label: { en: "Arabic Brief", ar: "موجز عربي" },
      accessor: "description_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_de",
      label: { en: "German Brief", ar: "موجز ألماني" },
      accessor: "description_de",
      type: "editor",
      required: true
    },
    {
      identifier: "seo_keywords",
      label: "Seo keywords",
      accessor: "seo_keywords",
      inList: true,
      type: "string",
      required: false,
    },
    {
      identifier: "seo_description",
      label: "Seo description",
      accessor: "seo_description",
      inList: true,
      type: "string",
      required: false,
    },
  ],
  filters: [
    {
      label: { en: "Blog Category", ar: "فئة المدونة" },
      name: "blog_category_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "blogCategory",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
