import { createAction } from "redux-starter-kit";

const actions = {
  activateMatrix: {
    request: createAction("admin/activateMatrix/request"),
    success: createAction("admin/activateMatrix/success"),
    failure: createAction("admin/activateMatrix/failure"),
  },
};

export default actions;
