import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "maintenanceRequestStatusEvent",
  pluralName: "maintenanceRequestStatusEvents",
  pathName: "maintenance_request_status_events",
  endpoint: "/admins/maintenance_requests/status_events",
  serverName: "event",
  serverPluralName: "events",
  displayName: "maintenance request status events",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  isPassive: true,
});
