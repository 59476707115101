import { Entity } from "@thewebops/admin-panel";
import ChangesetField from "../../components/ChangesetField";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "cmsProduct",
  pluralName: "cmsProducts",
  pathName: "cms_products",
  endpoint: "/admins/cms_products",
  serverName: "cms_product",
  serverPluralName: "cms_products",
  displayName: { en: "Cms Products", ar: "المنتجات" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  titleField: "name",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, masterDataOwner],
    edit: [superAdmin, applicationSupport, masterDataOwner],
    delete: [superAdmin, applicationSupport],
  },
  customNotifications: {
    create: {
      error: {
        title: "Error",
        message: (dataObject, language) => {
          return dataObject.cms_product[0];
        },
      },
    },
  },
  customErrorKeys: ["media_items"],
  customSagas: sagas,
  customReducers: reducers,
  customActions: actions,
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم بالانجليزية" },
      accessor: "name_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم بالعربية" },
      accessor: "name_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_de",
      label: { en: "Deutsch Name", ar: "الاسم الألماني" },
      accessor: "name_de",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "specifications_en",
      label: { en: "English Specifications", ar: "المواصفات بالإنجليزية" },
      accessor: "specifications_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "specifications_ar",
      label: { en: "Arabic Specifications", ar: "المواصفات بالعربية" },
      accessor: "specifications_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "specifications_de",
      label: { en: "German Specifications", ar: "المواصفات بالألمانية" },
      accessor: "specifications_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_en",
      label: { en: "English Description", ar: "الوصف الانجليزي" },
      accessor: "description_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_ar",
      label: { en: "Arabic Description", ar: "الوصف العربي" },
      accessor: "description_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_de",
      label: { en: "German Description", ar: "الوصف الألماني" },
      accessor: "description_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "price",
      label: { en: "Price", ar: "السعر" },
      accessor: "price",
      type: "number",
      required: true,
      inList: true,
    },

    {
      identifier: "installation_manual",
      label: "Installation Manual",
      accessor: "installation_manual",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "pdf",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Pdf", ar: "Pdf" },
          type: "pdf",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: false,
    },

    {
      identifier: "operation_manual",
      label: "Operation Manual",
      accessor: "operation_manual",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "pdf",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Pdf", ar: "Pdf" },
          type: "pdf",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: false,
    },

    {
      identifier: "video",
      label: "Video",
      accessor: "video",
      type: "dynamic",
      required: true,
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "video",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Video", ar: "الفيديو" },
          type: "video",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
    },
    {
      identifier: "images_attributes",
      label: "Images",
      accessor: "images",
      required: true,
      type: "dynamic",
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          identifier: "id",
          label: { en: "ID", ar: "الرقم التسلسلي" },
          accessor: "id",
          hideInShow: true,
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Image", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
    },

    {
      identifier: "seo_featured_image",
      label: "Seo Featured Image",
      accessor: "seo_featured_image",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },

        {
          useGallery: true,
          identifier: "file",
          label: { en: "Logo", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: false,
    },
    {
      identifier: "cms_product_sector_id",
      label: { en: "Cms Product Sector", ar: "قطاع المنتج" },
      formAccessor: (dataObject) => dataObject.cms_product_sector?.id,
      accessor: (dataObject) => dataObject.cms_product_sector?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "cmsProductSector",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "cms_brand_id",
      label: { en: "Cms Brand", ar: "الماركة" },
      formAccessor: (dataObject) => dataObject.cms_brand?.id,
      accessor: (dataObject) => dataObject.cms_brand?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "cmsBrand",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "offers",
      label: { en: "Offers", ar: "العروض" },
      multiple: true,
      type: "reference",
      accessor: "offers",
      optionsEntity: {
        name: "offer",
        labelField: "name",
        valueField: "id",
      },
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "current_offer",
      label: { en: "Current Offer", ar: "العرض الحالي" },
      type: "string",
      formAccessor: (dataObject) => dataObject.current_offer?.id,
      accessor: (dataObject) => dataObject.current_offer?.name,
      inList: false,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "seo_description",
      label: "Seo description",
      accessor: "seo_description",
      inList: true,
      type: "string",
      required: false,
    },
    {
      identifier: "seo_keywords",
      label: "Seo keywords",
      accessor: "seo_keywords",
      inList: true,
      type: "string",
      required: false,
    },
    {
      identifier: "seo_featured_image",
      label: "Seo featured_image",
      accessor: "seo_featured_image",
      hideInEdit: true,
      hideInCreate: true,
      inList: true,
      type: "image",
      required: false,
    },
  ],
  filters: [
    {
      label: { en: "Cms Product Sector", ar: "قطاع المنتج" },
      name: "cms_product_sector_id",
      type: "select",
      multiple: true,
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "cmsProductSector",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Cms Brand", ar: "الماركة" },
      name: "cms_brand_id",
      type: "select",
      multiple: true,
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "cmsBrand",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Start price", ar: "السعر المبدئي" },
      name: "start_price",
      type: "number",
      accessor: "start_price",
    },
    {
      label: { en: "End price", ar: "السعر النهائي" },
      name: "end_price",
      type: "number",
      accessor: "end_price",
    },
    {
      label: { en: "Search", ar: "بحث" },
      name: "query",
      type: "searchInput",
      // fieldIdentifier: "name_en",
    },
  ],
});
