import React, { useState } from "react";
import { InputNumber } from "antd";
import { CheckOutlined, EditFilled as EditIcon } from "@ant-design/icons";

import { Cell, CheckWrapper, EditIndication, EdititableValueWrapper } from "./styled";

const PointCell = ({ value, onChange }) => {
  const { points, id } = value || {};
  const [cellValue, setCellValue] = useState(points);

  const [isEdit, setIsEdit] = useState(false);

  const onEdit = () => {
    setIsEdit(!isEdit);
  };
  const onSave = () => {
    onEdit();
  onChange({ id, points: cellValue });
  };

  return (
    <Cell isEdit={isEdit}>
      {isEdit ? (
        <div onBlur={onSave}>
          <InputNumber onChange={setCellValue} className="cell-input-field" value={cellValue} min={0} />
          <CheckWrapper>
            <CheckOutlined onClick={onSave} />
          </CheckWrapper>
        </div>
      ) : (
        <EdititableValueWrapper onClick={onEdit}>
          <span>{cellValue} </span>
          <EditIndication>
            <EditIcon />
          </EditIndication>
        </EdititableValueWrapper>
      )}
    </Cell>
  );
};

export default PointCell;
