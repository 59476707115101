import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  accounant,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "inquiry",
  pluralName: "inquiries",
  pathName: "inquiry",
  endpoint: "/admins/inquiries",
  serverName: "inquiry",
  serverPluralName: "inquiries",
  displayName: { en: "Inquiries", ar: "الاستفسارات" },
  titleField: "name",
  showSearch: true,
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      accounant,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent],
    edit: [superAdmin, applicationSupport, callCenterAgent],
    delete: [superAdmin, applicationSupport],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
    },
    {
      identifier: "email",
      label: { en: "Email", ar: "البريد الإلكتروني" },
      accessor: "email",
      inList: true,
      type: "email",
    },
    {
      identifier: "phone_number",
      label: { en: "Phone number", ar: "رقم التليفون" },
      accessor: "phone_number",
      inList: true,
      type: "number",
    },
    {
      identifier: "message",
      label: { en: "Message", ar: "الرسالة" },
      accessor: "message",
      type: "text",
    },
    {
      identifier: "subject",
      label: { en: "Subject", ar: "الموضوع" },
      accessor: "subject",
      inList: true,
      type: "select",
      options: [
        { value: "ask", label: { en: "Ask", ar: "إستعلام" } },
        { value: "complain", label: { en: "Complain", ar: "شكوى" } },
        { value: "suggestion", label: { en: "Suggestion", ar: "اقتراح" } },
        { value: "work_with_us", label: { en: "Work With Us", ar: "اعمل معنا" } },
      ],
    },
  ],
  filters: [
    {
      label: { en: "Subject", ar: "نوع الهدية" },
      name: "subject",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "ask", label: { en: "Ask", ar: "إستعلام" } },
          { value: "complain", label: { en: "Complain", ar: "شكوى" } },
          { value: "suggestion", label: { en: "Suggestion", ar: "اقتراح" } },
          { value: "work_with_us", label: { en: "Work With Us", ar: "اعمل معنا" } },
        ],
      },
    },
  ],
});
