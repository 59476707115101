import { Entity } from "@thewebops/admin-panel";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;
export default new Entity({
  name: "matrix",
  pluralName: "matrix",
  pathName: "matrices",
  endpoint: "/admins/matrices",
  serverName: "matrix",
  serverPluralName: "matrices",
  displayName: { en: "Matrices", ar: "المصفوفات" },
  showSearch: true,
  allowEdit: true,
  allowDelete: false,
  allowCreate: true,
  showRowActions: true,
  titleField: "name",
  entityIdentifier: "id",
  customActions: actions,
  customReducers: reducers,
  authorizedRoles: {
    entity: [
      superAdmin,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, masterDataOwner],
    edit: [superAdmin, masterDataOwner],
    delete: [superAdmin],
  },
  customSagas: sagas,
  rowActions: [
    {
      active: (matrix) => !matrix.active,
      label: { en: "Activate", ar: "تفعيل" },
      name: "activateMatrix",
      authorizedRoles: [superAdmin, masterDataOwner],
    },
  ],
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
    },
    {
      identifier: "active",
      label: { en: "Active", ar: "مفعلة" },
      accessor: "active",
      inList: true,
      type: "toggle",
      hideInEdit: true,
      hideInCreate: true,
    },
  ],
});
