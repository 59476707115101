import { takeLatest, call, put, select } from "redux-saga/effects";
import { request, entitiesActions, showNotification, filtersToAttributes, getEntity } from "@thewebops/admin-panel";
import i18n from "i18next";
import actions from "./actions";


function* toggleArchive(action) {
  const { item, entity } = action.payload;
  const { success, failure } = actions.toggleArchive;
  try {
    yield call(request, {
      url: `${entity.endpoint}/${item.id}/toggle_archive`,
      method: "put",
      authenticated: true,
      data: {
      },
    });
    yield put(success());
    showNotification(
      null,
      "success",
      // i18n.t("homeowner:toggleArchive"),
    );
    yield put(entitiesActions[entity.name]["list"]["request"]({ entity }));

  } catch (error) {
    yield put(failure());

    showNotification(null, "error", error.response.data.error[0]);
  }
}




function* watchToggleArchiveSaga() {
  const { request } = actions.toggleArchive;

  yield takeLatest(request, toggleArchive);
}

export default [watchToggleArchiveSaga];
