const dashboardConfig = {
  groups: [
    {
      endpoint: "admins/dashboard/dashboard_data",
      components: [
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: "Yesterday's orders",
              },
              body: {
                accessor: "yesterday_orders_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: "Today's orders",
              },
              body: {
                accessor: "today_orders_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: "Total orders",
              },
              body: {
                accessor: "total_orders_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: "Total number of technicians",
              },
              body: {
                accessor: "total_technicians_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: "Total number of homeowners",
              },
              body: {
                accessor: "total_homeowners_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: "Total number of jobs",
              },
              body: {
                accessor: "total_jobs_count",
              },
            },
          },
        },
      ],
    },
    {
      endpoint: "admins/dashboard/dashboard_charts",
      components: [
        {
          type: "graph",
          dataSource: "server",
          config: {
            type: "pie",
            data: {
              title: {
                staticValue: { en: "Order Type", ar: "نوع الطلب  " },
              },
              graphData: {
                accessor: "order_per_type",
              },
            },
            height: 300,
            labelFontSize: 10,
            angleField: "order_count",
            colorField: "type",
            width: {
              tablet: "100%",
              desktop: "33%",
              mobile: "100%",
            },
          },
        },
      ],
    },
    {
      endpoint: "admins/dashboard/dashboard_charts",
      components: [
        {
          type: "graph",
          dataSource: "server",
          config: {
            type: "pie",
            data: {
              title: {
                staticValue: { en: "SAP Order Type", ar: "نوع الطلب  " },
              },
              graphData: {
                accessor: "order_per_sap_type",
              },
            },
            height: 300,
            labelFontSize: 10,
            angleField: "order_count",
            colorField: "sap_type",
            width: {
              tablet: "100%",
              desktop: "33%",
              mobile: "100%",
            },
          },
        },
      ],
    },
    {
      endpoint: "admins/dashboard/dashboard_charts",
      components: [
        {
          type: "graph",
          dataSource: "server",
          config: {
            type: "pie",
            data: {
              title: {
                staticValue: { en: "Technician per points tier", ar: "فني لكل فئة من النقاط  " },
              },
              graphData: {
                accessor: "technicians_per_points_tier",
              },
            },
            height: 300,
            labelFontSize: 10,
            angleField: "number_of_technicians",
            colorField: "points_tier_name",
            width: {
              tablet: "100%",
              desktop: "33%",
              mobile: "100%",
            },
          },
        },
      ],
    },
    {
      endpoint: "admins/dashboard/dashboard_charts",
      components: [
        {
          type: "graph",
          dataSource: "server",
          config: {
            type: "pie",
            data: {
              title: {
                staticValue: { en: "Technician Gift Per Status", ar: "هدية فني لكل حالة" },
              },
              graphData: {
                accessor: "technician_gift_per_status",
              },
            },
            height: 300,
            labelFontSize: 10,
            angleField: "technician_gift_count",
            colorField: "status",
            width: {
              tablet: "100%",
              desktop: "33%",
              mobile: "100%",
            },
          },
        },
      ],
    },
  ],
};

export default dashboardConfig;
