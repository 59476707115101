import { takeLatest, call, put, select } from "redux-saga/effects";
import { request, showNotification, filtersToAttributes, getEntity } from "@thewebops/admin-panel";
import actions from "./actions";

function* exportBarcodes(action) {
  const { entity, item } = action.payload;
  const storeEntity = yield select(getEntity, entity.pluralName);
  const { success, failure } = actions.exportBarcodes;
  try {
    const storeEntity = yield select(getEntity, entity.pluralName);
    console.log(storeEntity, item);
    const order_product = storeEntity.current.order_products.filter(
      (order_product) => order_product.product_id === item.id,
    )?.[0];
    const response = yield call(request, {
      url: `/admins/order_products/export_files`,
      method: "post",
      authenticated: true,
      data: {
        id: order_product.id,
      },
    });
    yield put(success());
    showNotification(entity.getDisplayName(), "success");
  } catch (error) {
    yield put(failure({ errors: error.response.data.errors }));
    showNotification(entity.getDisplayName(), "error", error.response.data.errors);
  }
}
function* watchExportTransactions() {
  const { request } = actions.exportBarcodes;
  yield takeLatest(request, exportBarcodes);
}

function* downloadFile(action) {
  const { entity, item } = action.payload;
  const storeEntity = yield select(getEntity, entity.pluralName);
  const { success, failure } = actions.downloadFile;
  try {
    const storeEntity = yield select(getEntity, entity.pluralName);
    console.log(storeEntity, item);
    const order_product = storeEntity.current.order_products.filter(
      (order_product) => order_product.product_id === item.id,
    )?.[0];
    const response = yield call(request, {
      url: `/admins/order_products/export_files/${order_product.id}`,
      method: "put",
      authenticated: true,
    });
    window.open(response.data.order_product.file_url);
    yield put(success());
    showNotification(entity.getDisplayName(), "success");
  } catch (error) {
    yield put(failure({ errors: error.response.data.errors }));
    showNotification(entity.getDisplayName(), "error", error.response.data.errors);
  }
}
function* watchDownloadFile() {
  const { request } = actions.downloadFile;
  yield takeLatest(request, downloadFile);
}

export default [watchExportTransactions, watchDownloadFile];
