import { takeLatest, call, put } from "redux-saga/effects";
import i18n from "i18next";
import { showNotification, entitiesActions } from "@thewebops/admin-panel";
import { request } from "@thewebops/admin-panel";
import actions from "./actions";

function* activateMatrix(action) {
  const { item, entity } = action.payload;
  const { success, failure } = actions.activateMatrix;
  try {
    yield call(request, {
      url: `${entity.endpoint}/${item.id}/toggle_activity`,
      method: "put",
      authenticated: true,
    });
    yield put(success());
    showNotification(entity.getDisplayName(), "success");
    yield put(entitiesActions[entity.name].list.request({ entity }));
  } catch (error) {
    yield put(failure({ error: error.response.data.errors }));
    showNotification(entity.getDisplayName(), "error", error.response.data.errors);
  }
}

function* watchEntityActivateMatrix() {
  const { request } = actions.activateMatrix;
  yield takeLatest(request, activateMatrix);
}

export default [watchEntityActivateMatrix];
