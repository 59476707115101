import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin, applicationSupport, marketingOfficer, callCenterSupervisor } = ROLES;

export default (authConfig) =>
  new Entity({
    title: { en: "Send Notifications", ar: "إرسال اشعارات" },
    name: "sendNotifications",
    subTitle: `Please fill this form to send a broadcast`,
    path: "/broadcasts",
    actionLabel: "Send Notifications",
    showInSidebar: true,
    showInCollapsedSideBar: true,
    showAuthFormNotification: false,
    action: "create",
    identifier: "notification",
    authorizedRoles: {
      entity: [superAdmin, applicationSupport, marketingOfficer, callCenterSupervisor],
      create: [superAdmin, applicationSupport, marketingOfficer, callCenterSupervisor],
      edit: [superAdmin, applicationSupport, marketingOfficer, callCenterSupervisor],
      delete: [superAdmin, applicationSupport],
    },
    fields: [
      {
        label: "Channel Type",
        required: true,
        type: "checkbox",
        identifier: "channel_types",
        checkboxConfig: { format: "array" },
        options: [
          {
            value: "misr_sms",
            label: { en: "SMS", ar: "رسالة نصية" },
          },
          {
            value: "fire_base",
            label: { en: "Push notification", ar: "اشعار" },
          },
          {
            value: "mail_gun",
            label: { en: "ِEmail notification", ar: "بريد" },
          },
        ],
      },
      {
        label: "Bulk notifiables",
        required: false,
        type: "checkbox",
        dependee: true,
        identifier: "bulk_notifiables",
        checkboxConfig: { format: "array" },
        options: [
          {
            value: "technician",
            label: { en: "Technician", ar: "عمال" },
          },
          {
            value: "homeowner",
            label: { en: "Homeowners", ar: "ملاك" },
          },
        ],
      },
      {
        identifier: "technician",
        label: { en: "Technicians", ar: "عمال" },
        accessor: "technician",
        required: false,
        type: "reference",
        dependsOn: "bulk_notifiables",
        hideOnValue: ["technician"],
        multiple: true,
        isSearchable: true,
        optionsEntity: {
          name: "technician",
          valueField: "id",
          labelField: "name",
          valueLabel: "id",
        },
      },
      {
        identifier: "homeowner",
        label: { en: "Homeowners", ar: "ملاك" },
        accessor: "homeowner",
        required: false,
        type: "reference",
        dependsOn: "bulk_notifiables",
        hideOnValue: ["homeowner"],
        multiple: true,
        isSearchable: true,
        optionsEntity: {
          name: "homeowner",
          valueField: "id",
          labelField: "name",
          valueLabel: "id",
        },
      },
      {
        label: "Title",
        required: true,
        type: "string",
        identifier: "title",
      },
      {
        label: "Body",
        required: true,
        type: "text",
        identifier: "body",
      },
      {
        label: "Image URL",
        required: true,
        type: "string",
        identifier: "payload_image",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}/broadcasts`,
      method: "post",
      successMessage: "Notification has been broadcasted",
      errorMessage: "Couldn't send notification",
      authenticated: true,
      payloadWrapper: {
        name: "notification",
        fields: [
          "channel_types",
          "bulk_notifiables",
          "title",
          "body",
          "event_key",
          "homeowner",
          "technician",
          "payload_image",
        ],
      },
      saveToLocalStorage: false,
      updateAuthAfterSuccess: false,
      persistAuthAfterSuccess: true,
    },
  });
