import { currentRoleEqualTo } from "@thewebops/admin-panel";
import moment from "moment";

const tablesSection = [
  {
    identifier: "history",
    label: { en: "Homeowner's Other Maintenance Requests", ar: "طلبات الصيانة الأخري للمالك" },
    accessor: (dataObject) => dataObject.other_homeowner_maintenance_requests?.maintenance_requests,
    type: "table",
    hideInCreate: true,
    hideInEdit: true,
    lgCol: 12,
    mdCol: 12,
    fields: [
      {
        identifier: "id",
        label: { en: "ID", ar: "الرقم التسلسلي" },
        accessor: (dataObject) => dataObject.id,
        inList: true,
        usedToShow: true,
      },
      {
        identifier: "start_time",
        label: { en: "Start Time", ar: "وقت البدء" },
        accessor: (dataObject) =>
          dataObject.start_time ? moment(dataObject.start_time).format("Do MMMM YYYY - h:mm a") : null,
        type: "string",
        inList: true,
      },
      {
        identifier: "end_time",
        label: { en: "End Time", ar: "وقت الانتهاء" },
        accessor: (dataObject) =>
          dataObject.end_time ? moment(dataObject.end_time).format("Do MMMM YYYY - h:mm a") : null,
        type: "string",
        inList: true,
      },
      {
        identifier: "status",
        label: { en: "status", ar: "حالة" },
        accessor: (dataObject) => dataObject.status,
        type: "string",
        inList: true,
      },
      {
        identifier: "product_sector_id",
        label: { en: "Product Sector", ar: "قطاع المنتج" },
        accessor: (dataObject) => dataObject.product_sector?.name,
        type: "string",
        inList: true,
      },
      {
        identifier: "homeowner_id",
        label: { en: "Homeowner", ar: "المالك" },
        accessor: (dataObject) => dataObject.homeowner?.name,
        inList: true,
        type: "string",
      },
      {
        identifier: "property_id",
        label: { en: "Property", ar: "منشأه" },
        accessor: (dataObject) => dataObject.property?.name,
        type: "string",
        inList: true,
      },
      {
        identifier: "created_at",
        label: { en: "Date Of Request", ar: "تاريخ الطلب" },
        accessor: (dataObject) =>
          dataObject.created_at ? moment(dataObject.created_at).format("Do MMMM YYYY - h:mm a") : null,
        type: "date",
        inList: true,
      },
    ],
    tableConfig: {
      name: "maintenanceRequest",
      type: "static",
    },
  },
];

export default tablesSection;
