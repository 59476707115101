import { Entity } from "@thewebops/admin-panel";
import statusesSection from "./sections/statuses";
import tablesSection from "./sections/tables";
import ChangesetField from "../../components/ChangesetField";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
} = ROLES;

export default new Entity({
  name: "draftOrder",
  pluralName: "draftOrder",
  pathName: "draft_order",
  endpoint: "/admins/draft_orders",
  serverName: "draft_order",
  serverPluralName: "draft_orders",
  displayName: { en: "Draft Order", ar: "إنشاء طلب" },
  allowEdit: (dataObject) => {
    return !["finalized", "canceled"].includes(dataObject.status);
  },
  showRowActions: true,
  showSearch: true,
  titleField: "sap_order_number",
  entityIdentifier: "id",
  isFullWidth: true,
  import: {
    enabled: true,
    extensions: ["xlsx", "csv", "application/vnd.openxmlformats-officedocument"],
    paramName: "file",
    method: "post",
    endpoint: "/admins/draft_orders/import_sheets",
    authorizedRoles: [superAdmin, applicationSupport, salesRep, crSpecialist, crSectionHead, masterDataOwner],
  },

  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
    ],
    create: [
      superAdmin,
      applicationSupport,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
    ],
    edit: [
      superAdmin,
      applicationSupport,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
    ],
    delete: [
      superAdmin,
      applicationSupport,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
    ],
  },
  rowActions: [
    {
      type: "formModal",
      label: "Update Status",
      authorizedRoles: [superAdmin, warehouseClerk, crWarehouse, crSpecialist, crSectionHead, applicationSupport],
      active: (dataObject) => {
        return !["finalized", "canceled"].includes(dataObject.status);
      },
      entityConfig: {
        name: "draftOrder",
        fieldsOverrides: {
          draft_order_status_event: {
            hideInEdit: false,
          },
        },
        referencedEntityField: "id",
        entityOverrides: {
          customEditEndpoint: ({ entity, data, id }) => {
            console.log(id);
            return `${entity.endpoint}/${id}/update_status/`;
          },
        },
      },
      formAction: "edit",
    },
  ],
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "created_on",
      label: { en: "Created On", ar: "تاريخ الانشاء" },
      accessor: "created_on",
      inList: true,
      hideInEdit: true,
      type: "datetime",
    },
    {
      identifier: "sap_order_number",
      label: { en: "SAP Order Number", ar: " SAP رقم الطلب" },
      accessor: "sap_order_number",
      type: "string",
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "branch_retailer",
      label: { en: "Branch Retailer", ar: "فرع بائع تجزئة" },
      accessor: "branch_retailer",
      type: "string",
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "order_type",
      label: { en: "Order Type", ar: "نوع الطلب" },
      accessor: "order_type",
      type: "string",
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "sap_order_type",
      label: { en: "SAP Order Type", ar: "SAP نوع الطلب" },
      accessor: "sap_order_type",
      type: "string",
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "client_id",
      label: { en: "Client", ar: "العميل" },
      formAccessor: (dataObject) => dataObject.client?.id,
      accessor: (dataObject) => dataObject.client?.name,
      inList: true,
      hideInEdit: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "client",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "paper_trail/versions",
      accessor: (dataObject) => (dataObject.versions ? dataObject.versions["paper_trail/versions"] : null),
      formAccessor: (dataObject) => (dataObject.versions ? dataObject.versions["paper_trail/versions"] : null),
      label: "History",
      hideInShow: false,
      hideInEdit: true,
      hideInCreate: true,
      type: "dynamic",
      fields: [
        {
          identifier: "id",
          label: "ID",
          accessor: "id",
          inList: true,
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "whodunnit",
          accessor: "whodunnit",
          label: "whodunnit",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "event",
          accessor: "event",
          label: "event",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "changeset",
          accessor: "changeset",
          component: ChangesetField,
          label: "changeset",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
      ],
    },
    ...statusesSection,
    ...tablesSection,
  ],
  filters: [
    {
      label: { en: "Start date", ar: "تاريخ البدء" },
      name: "start_date",
      type: "date",
      accessor: "start_date",
    },
    {
      label: { en: "End date", ar: "تاريخ الانتهاء" },
      name: "end_date",
      type: "date",
      accessor: "end_date",
    },
    {
      label: { en: "Status", ar: "الحالة" },
      name: "status",
      accessor: "status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "in_progress", label: { en: "In Progress", ar: "في تقدم" } },
          { value: "finalized", label: { en: "Finalized", ar: "كامل" } },
          { value: "canceled", label: { en: "Canceled", ar: "إلغاء" } },
          { value: "pending_approval", label: { en: "Pending Approval", ar: "ما زال يحتاج بتصدير" } },
        ],
      },
    },
    {
      label: { en: "Order type", ar: "نوع الطلب" },
      name: "order_type",
      accessor: "order_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "normal", label: { en: "Normal", ar: "عادي" } },
          { value: "refund", label: { en: "Refund", ar: "إعادة مال" } },
        ],
      },
    },
    {
      label: { en: "Sap Order Type", ar: "نوع الطلب sap" },
      name: "sap_order_type",
      accessor: "sap_order_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "OR", label: "OR" },
          { value: "YREG", label: "YREG" },
          { value: "RE", label: "RE" },
          { value: "YBFD", label: "YBFD" },
          { value: "YFRE", label: "YFRE" },
          { value: "YBRE", label: "YBRE" },
        ],
      },
    },

    {
      label: { en: "SAP Order Number", ar: "الفني" },
      name: "sap_order_number",
      accessor: "sap_order_number",
      type: "select",
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "draftOrder",
          labelField: "sap_order_number",
          valueField: "sap_order_number",
        },
      },
    },
  ],
});
