import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

const ErrorMessageHandle = ({ value }) => (
  <Fragment>
    {value?.split("\n").map((message, index) => (
      <Typography component="p" key={index}>
        {" "}
        {message}{" "}
      </Typography>
    ))}
  </Fragment>
);

export default ErrorMessageHandle;
