import { takeLatest, call, put } from "redux-saga/effects";
import { request } from "@thewebops/admin-panel";
import actions from "./actions";

function* pointsTierGift(action) {
  const { success, failure } = actions.pointsTierGifts;
  const { entity, data } = action.payload;

  const params = entity.applyCustomRequestParams("edit");
  const endpoint = entity.getEndpointForAction("default", action.payload);
  try {
    yield call(request, {
      url: endpoint,
      method: "patch",
      authenticated: true,
      data,
      params,
    });
    const response = yield call(request, {
      url: endpoint,
      method: "get",
      authenticated: true,
      data,
      params,
    });
    yield put(success(response.data.points_tier_gifts));
    entity.handleNotification("success");
  } catch (error) {
    yield put(failure(error.response.data));
    entity.handleNotification("error", error.response.data);
  }
}

function* watchPointsTierGift() {
  const { request } = actions.pointsTierGifts;
  yield takeLatest(request, pointsTierGift);
}

export default [watchPointsTierGift];
