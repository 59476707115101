import { Entity } from "@thewebops/admin-panel";
import statusesSection from "./sections/statuses";
import ChangesetField from "../../components/ChangesetField";
import mapValidationConfig from "entities/mapValidationConfig";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  accounant,
} = ROLES;

export default new Entity({
  name: "job",
  pluralName: "jobs",
  pathName: "job",
  endpoint: "/admins/jobs",
  serverName: "job",
  serverPluralName: "jobs",
  displayName: { en: "Jobs", ar: "المهام" },
  allowCreate: true,
  allowEdit: (job) => !["canceled", "fully_canceled"].includes(job.points_status),
  showRowActions: true,
  showSearch: true,
  titleField: "code",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      callCenterSupervisor,
      callCenterAgent,
      crSpecialist,
      crSectionHead,
      crWarehouse,
    ],
    create: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, crSectionHead],
    edit: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, crSectionHead, accounant],
    delete: [superAdmin, applicationSupport],
  },
  rowActions: [
    {
      type: "formModal",
      label: "Update Status",
      authorizedRoles: [superAdmin, applicationSupport, crSectionHead],
      entityConfig: {
        name: "job",
        fieldsOverrides: {
          points_status_event: {
            hideInEdit: false,
          },
        },
        referencedEntityField: "job_id",
        entityOverrides: {
          customEditEndpoint: ({ entity, data, id }) => `${entity.endpoint}/${id}/update_status/`,
        },
      },
      formAction: "edit",
    },
  ],

  customNotifications: {
    edit: {
      error: {
        title: { en: "Not Allowed", ar: "غير مسموح" },
        message: (dataObject) => dataObject.client_id,
      },
    },
  },

  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "client_phone_number",
      label: { en: "Submitted Phone Number", ar: "رقم هاتف العميل" },
      accessor: "client_phone_number",
      type: "string",
      inList: true,
      required: true,
      hideInEdit: (job) => job.points_status != "in_progress",
    },
    {
      identifier: "homeowner_phone_number",
      label: { en: "Homeowner Phone Number", ar: "رقم هاتف العميل" },
      formAccessor: (dataObject) => dataObject.homeowner?.id,
      accessor: (dataObject) => dataObject.homeowner?.phone_number,
      type: "reference",
      optionsEntity: {
        name: "homeowner",
        labelField: "phone_number",
        valueField: "id",
        displayAsLink: true,
      },
      inList: false,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "client_name",
      label: { en: "Client Name", ar: "اسم العميل" },
      accessor: "client_name",
      inList: true,
      type: "string",
      required: true,
      hideInEdit: (job) => job.points_status != "in_progress",
    },
    {
      identifier: "retailer_name",
      label: { en: "Retailer Name", ar: "اسم بائع التجزئة" },
      accessor: "retailer_name",
      inList: true,
      type: "string",
      required: true,
      hideInEdit: (job) => job.points_status != "in_progress",
    },
    {
      identifier: "code",
      label: { en: "Job Code", ar: "كود المهام" },
      accessor: "code",
      inList: true,
      type: "string",
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "points",
      label: { en: "Points", ar: "النقاط" },
      accessor: (dataObject) => (dataObject?.points ? dataObject.points : "_"),
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "client_address",
      label: { en: "Client Address", ar: "عنوان العميل" },
      accessor: "client_address",
      type: "string",
      required: true,
      hideInEdit: (job) => job.points_status != "in_progress",
    },
    {
      identifier: "technician_id",
      label: { en: "Technician", ar: "الفني" },
      type: "reference",
      formAccessor: (dataObject) => dataObject.main_technician?.id,
      accessor: (dataObject) => dataObject.main_technician?.name,
      isSearchable: true,
      optionsEntity: {
        name: "technician",
        labelField: "name",
        valueField: "id",
      },
      hideInEdit: true,
      required: true,
      hideInEdit: (job) => job.points_status != "in_progress",
    },
    {
      identifier: "technicians_attributes",
      label: { en: "Squad", ar: "الفريق" },
      accessor: "technician_jobs",
      type: "reference",
      multiple: true,
      isSearchable: true,
      useObjectArray: true,
      optionsEntity: {
        name: "technician",
        labelField: "name",
        valueField: "id",
        valueLabel: "technician_id",
      },
      hideInEdit: (job) => job.points_status != "in_progress",
    },
    {
      identifier: "product_items_attributes",
      label: { en: "Product Items", ar: "رقم المنتج" },
      accessor: "product_items",
      required: true,
      type: "dynamic",
      hideInEdit: (job) => job.points_status != "in_progress",
      fields: [
        {
          identifier: "id",
          label: { en: "Product Items", ar: "رقم المنتج" },
          accessor: "barcode",
          formAccessor: "id",
          type: "reference",
          isSearchable: true,
          optionsEntity: {
            name: "productItem",
            labelField: "barcode",
            valueField: "id",
            filterParams: [{ name: "available_for_scanning_value", value: true, type: "both" }],
          },
        },
        {
          identifier: "pin_point",
          accessor: "pin_point",
          label: { en: "Location", ar: "نقطة الإلتقاء" },
          type: "markerMap",
          inShow: false,
          mapConfig: {
            addressFieldIdentifier: "address_name",
            lngFieldIdentifier: "lng",
            latFieldIdentifier: "lat",
            governorateFieldIdentifier: "governorate",
          },
        },

        {
          identifier: "lat",
          accessor: "lat",
          hideInShow: true,
        },

        {
          identifier: "lng",
          accessor: "lng",
          hideInShow: true,
        },
      ],
    },
    {
      identifier: "pin_point",
      accessor: "pin_point",
      type: "markerMap",
      mapConfig: {
        lngFieldIdentifier: "lng",
        latFieldIdentifier: "lat",
      },
      hideInCreate: true,
      hideInEdit: true,
    },
    ...statusesSection,
    {
      identifier: "governorate_id",
      label: { en: "Governorate", ar: "المحافظة" },
      type: "reference",
      inList: true,
      formAccessor: (dataObject) => dataObject.governorate?.id,
      accessor: (dataObject) => dataObject.governorate?.name_en,
      optionsEntity: {
        name: "governorate",
        labelField: "name",
        valueField: "id",
      },
      required: true,
      hideInEdit: (job) => job.points_status != "in_progress",
    },
    {
      identifier: "living_area_id",
      label: { en: "Living Area", ar: "المنطقة السكنية" },
      type: "reference",
      inList: true,
      formAccessor: (dataObject) => dataObject.living_area?.id,
      accessor: (dataObject) => dataObject.living_area?.name_en,
      optionsEntity: {
        name: "livingArea",
        labelField: "name",
        valueField: "id",
        filterParams: [{ name: "governorate_id", value: ["state", "governorate_id"], type: "both" }],
      },
      required: true,
      hideInEdit: (job) => job.points_status != "in_progress",
    },
    {
      identifier: "property_type_id",
      label: { en: "Property Type", ar: "أنواع الممتلكات" },
      type: "reference",
      inList: true,
      formAccessor: (dataObject) => dataObject.property_type?.id,
      accessor: (dataObject) => dataObject.property_type?.title,
      optionsEntity: {
        name: "propertyType",
        labelField: "title",
        valueField: "id",
      },
      required: true,
      hideInEdit: (job) => job.points_status != "in_progress",
    },
    {
      identifier: "client_id",
      label: { en: "Retailer", ar: "العميل" },
      type: "reference",
      inList: true,
      formAccessor: (dataObject) => dataObject.client?.id,
      accessor: (dataObject) => dataObject.client?.name,
      required: true,
      optionsEntity: {
        name: "client",
        labelField: "name",
        valueField: "id",
        filterParams: [
          {
            name: "client_type",
            value: "retailer",
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "warranty",
      label: { en: "Warranty", ar: "الضمان" },
      type: "reference",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      formAccessor: (dataObject) => dataObject.warranty?.id,
      accessor: (dataObject) => dataObject.warranty?.status,
      optionsEntity: {
        name: "warranty",
        labelField: "status",
        valueField: "id",
      },
    },

    {
      identifier: "pin_point",
      accessor: "pin_point",
      label: { en: "Location", ar: "نقطة الإلتقاء" },
      type: "markerMap",
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: (dataObject) => dataObject.points_status !== "done",
      mapConfig: {
        addressFieldIdentifier: "address_name",
        lngFieldIdentifier: "lng",
        latFieldIdentifier: "lat",
        governorateFieldIdentifier: "governorate",
      },
    },

    {
      identifier: "paper_trail/versions",
      accessor: (dataObject) => (dataObject.versions ? dataObject.versions["paper_trail/versions"] : null),
      formAccessor: (dataObject) => (dataObject.versions ? dataObject.versions["paper_trail/versions"] : null),
      label: "History",
      hideInShow: false,
      hideInEdit: true,
      hideInCreate: true,
      type: "dynamic",
      fields: [
        {
          identifier: "id",
          label: "ID",
          accessor: "id",
          inList: true,
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "whodunnit",
          accessor: "whodunnit",
          label: "whodunnit",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "event",
          accessor: "event",
          label: "event",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "changeset",
          accessor: "changeset",
          component: ChangesetField,
          label: "changeset",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
      ],
    },
  ],
  filters: [
    {
      label: { en: "Retailer", ar: "تم تعيين بائع التجزئة؟" },
      name: "retailer_assigned",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "true", label: "Assigned" },
          { value: "false", label: "Not Assigned" },
        ],
      },
    },
    {
      label: { en: "Status", ar: "تم تعيين بائع التجزئة؟" },
      name: "points_status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "in_progress", label: "In Progress" },
          { value: "earned", label: "Earned" },
          { value: "pending_inspection", label: "Pending Inspection" },
          { value: "done", label: "Done" },
          { value: "canceled", label: "Canceled" },
          { value: "fully_canceled", label: "Fully Canceled" },
        ],
      },
    },
  ],
});
