import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { push } from "connected-react-router";
const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  crSpecialist,
  crSectionHead,
  masterDataOwner,
  accounant,
} = ROLES;

export default new Entity({
  name: "homeowner",
  pluralName: "homeowner",
  pathName: "homeowners",
  endpoint: "/admins/homeowners",
  serverName: "homeowner",
  serverPluralName: "homeowners",
  displayName: { en: "Homeowners", ar: "الملاك" },
  allowCreate: false,
  allowEdit: true,
  showSearch: true,
  allowDelete: false,
  showRowActions: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  titleField: "phone_number",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      crSpecialist,
      crSectionHead,
      masterDataOwner,
      accounant,
    ],
    create: [superAdmin],
    edit: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, crSectionHead, masterDataOwner],
    delete: [superAdmin],
  },
  rowActions: [
    {
      label: { en: "Archive", ar: "حظر الحساب" },
      name: "toggleArchive",
      requireConfirmation: true,
      confirmationMessage: "Are you sure you want to archive this homeowner?",
      active: (dataObject) => !dataObject.discarded_at,
      authorizedRoles: [superAdmin],
    },

    {
      label: { en: "Unarchive", ar: "فك الحظر" },
      name: "toggleArchive",
      requireConfirmation: true,
      confirmationMessage: "Are you sure you want to unarchive this homeowner?",
      active: (dataObject) => dataObject.discarded_at,
      authorizedRoles: [superAdmin],
    },
  ],
  globalActions: [
    {
      label: { en: "Invite", ar: "ارسل دعوة" },
      name: "inviteHomeowner",
      authorizedRoles: [superAdmin, callCenterSupervisor, callCenterAgent, crSectionHead],
      customFunction: (dispatch, item) => {
        dispatch(push("invite_homeowner"));
      },
    },
  ],
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      required: true,
      type: "string",
    },
    {
      identifier: "email",
      label: { en: "Email", ar: "البريد الإلكتروني" },
      accessor: "email",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "phone_number",
      label: { en: "Phone number", ar: "رقم الهاتف" },
      accessor: "phone_number",
      inList: true,
      type: "string",
      hideInEdit: true,
      required: true,
    },
    {
      identifier: "homeowner_type_id",
      label: { en: "Homeowner Type", ar: "نوع المالك" },
      formAccessor: (dataObject) => dataObject.homwowner_type?.id,
      accessor: (dataObject) => dataObject.homeowner_type?.title,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "homeownerType",
        labelField: "title",
        valueField: "id",
      },
    },

    {
      identifier: "deleted",
      label: { en: "Deleted", ar: "محذوف" },
      accessor: (dataObject) => (dataObject.discarded_at ? "True" : "False"),
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
    },
  ],
  filters: [
    {
      label: { en: "Deleted", ar: " محذوف" },
      name: "deleted",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: true, label: { en: "True", ar: "نعم" } },
          { value: false, label: { en: "False", ar: "لا" } },
        ],
      },
    },
  ],
});
