import styled from "styled-components";

export const Cell = styled.div`
  cursor: ${(props) => (props.isEdit ? "pointer" : "text")};
  .cell-input-field {
    width: 80px;
  }
`;

export const HiddenHeader = styled.span`
  visibility: hidden;
`;

export const GiftCellWrapper = styled.div`
  font-weight: 500;
`;

export const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

export const EdititableValueWrapper = styled.div`
  cursor: pointer;
  position: relative;
  min-height: 2vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const EditIndication = styled.span`
  margin-left: 1vw;
`;

export const CheckWrapper = styled.span`
  margin-left: 1vw;
`;
