import event from "./Event";
import news from "./News";
import productSector from "./ProductSector";
import brand from "./Brand";
import faqCategory from "./FaqCategory";
import faq from "./Faq";
import blogCategory from "./BlogCategory";
import blog from "./Blog";
import inquiry from "./Inquiry";
import admin from "./Admin";
import pointsTier from "./PointsTier";
import solutionCategory from "./SolutionCategory";
import solution from "./Solution";
import solutionInquiry from "./SolutionInquiry.js";
import offer from "./Offer";
import product from "./Product";
import productItem from "./ProductItem";
import livingArea from "./LivingArea";
import order from "./Order/index";
import orderStatusEvent from "./Order/Event";
import client from "./Client";
import technician from "./Technician/index";
import job from "./Job/index";
import import_sheet from "./ImportSheet";
import homeowner from "./Homeowner";
import property from "./Property";
import jobPointsStatusEvent from "./Job/PointsEvent";
import governorate from "./Governorate";
import homeownerType from "./HomeownerType";
import warranty from "./Warranty/index";
import maintenanceRequest from "./MaintenanceRequest/index";
import maintenanceRequestStatusEvent from "./MaintenanceRequest/Event";
import draftOrderStatusEvent from "./DraftOrder/Event";
import draftOrder from "./DraftOrder/index";
import gift from "./Gift";
import report from "./Report";
import technician_gift from "./TechnicianGift/index";
import zone from "./Zone";
import agent from "./Agent";
import pointsTransaction from "./PointsTransaction/index";
import propertyType from "./PropertyType";
// import page from "./Page";
// import sectionSchema from "./SectionSchema";
import media from "./Media";
import warrantyStatusEvent from "./Warranty/Event";
import technicianGiftStatusEvent from "./TechnicianGift/Event";
import store from "./Store";

import draftProduct from "./DraftProduct";
import PointTierGift from "./PointsTierGift";
import exportFile from "./ExportFile";
import cmsBrand from "./CmsBrand";
import cmsProductSector from "./CmsProductSector";
import cmsProduct from "./CmsProduct/index";
import matrix from "./Matrix/index";
import pointEvent from "./PointEvent";
import component from "./Component";

const userDefinedEntities = {
  event,
  admin,
  faqCategory,
  faq,
  blogCategory,
  blog,
  solutionCategory,
  inquiry,
  news,
  productSector,
  brand,
  pointsTier,
  solution,
  solutionInquiry,
  offer,
  product,
  productItem,
  livingArea,
  propertyType,
  order,
  client,
  orderStatusEvent,
  technician,
  job,
  import_sheet,
  homeowner,
  property,
  jobPointsStatusEvent,
  governorate,
  homeownerType,
  maintenanceRequest,
  draftOrderStatusEvent,
  draftOrder,
  gift,
  PointTierGift,
  report,
  technician_gift,
  zone,
  agent,
  pointsTransaction,
  // sectionSchema,
  media,
  // page,
  draftProduct,
  warrantyStatusEvent,
  warranty,
  exportFile,
  maintenanceRequestStatusEvent,
  technicianGiftStatusEvent,
  store,
  cmsBrand,
  cmsProductSector,
  cmsProduct,
  matrix,
  pointEvent,
  component,
};

const groups = [
  {
    name: "CMS",
    type: "normal",
    entities: [
      faqCategory,
      faq,
      blogCategory,
      blog,
      inquiry,
      solutionCategory,
      news,
      event,
      solution,
      solutionInquiry,
      offer,
      report,
      store,
      cmsBrand,
      cmsProductSector,
      cmsProduct,
    ],
  },
  {
    name: "ungrouped",
    entities: [
      admin,
      pointsTier,
      PointTierGift,
      productSector,
      product,
      brand,
      productItem,
      propertyType,
      livingArea,
      order,
      client,
      technician,
      job,
      import_sheet,
      property,
      governorate,
      homeowner,
      homeownerType,
      maintenanceRequest,
      draftOrder,
      gift,
      technician_gift,
      zone,
      agent,
      pointsTransaction,
      // sectionSchema,
      // page,
      media,
      warranty,
      exportFile,
      matrix,
      pointEvent,
      // component,
    ],
  },
];
export { userDefinedEntities, groups };
