import { Entity } from "@thewebops/admin-panel";

const authorizedRoles = ["super_admin", "call_center_supervisor", "call_center_agent", "cr_section_head"];

export default (authConfig) =>
  new Entity({
    title: "Invite technician",
    name: "inviteTechnician",
    subTitle: "Invite a technician",
    path: "/invite_technician",
    actionLabel: "Invite",
    action: "create",
    preventAccess: (auth) => auth?.role && !authorizedRoles.includes(auth.role),
    fields: [
      {
        label: { en: "Phone number", ar: "رقم الهاتف" },
        identifier: "phone_number",
        required: true,
        type: "string",
      },
    ],
    requestConfig: {
      url: `admins/technicians`,
      method: "post",
      authenticated: true,
      successMessage: "Technician invited",
      errorMessage: "Couldn't invite technician",
    },
  });
