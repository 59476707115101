import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "cmsProductSector",
  pluralName: "cmsProductSectors",
  pathName: "cms_product_sectors",
  endpoint: "/admins/cms_product_sectors",
  serverName: "cms_product_sector",
  serverPluralName: "cms_product_sectors",
  displayName: { en: "Cms Product Sectors", ar: "قطاعات المنتج" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  titleField: "name",
  entityIdentifier: "id",
  useFormData: true,
  customErrorKeys: ["media_items"],
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, marketingOfficer, masterDataOwner],
    edit: [superAdmin, applicationSupport, marketingOfficer, masterDataOwner],
    delete: [superAdmin, applicationSupport],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "logo",
      label: "Logo",
      accessor: "logo",
      type: "dynamic",
      preventMultiple: true,
      required: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Image", ar: "الصورة" },
          type: "image",
          accessor: "file",
          required: true,
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم بالانجليزية" },
      accessor: "name_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم بالعربية" },
      accessor: "name_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_de",
      label: { en: "Deutsch Name", ar: "الاسم الألماني" },
      accessor: "name_de",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "brief_en",
      label: { en: "English Brief", ar: "موجز إنجليزي" },
      accessor: "brief_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "brief_ar",
      label: { en: "Arabic Brief", ar: "موجز عربي" },
      accessor: "brief_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "brief_de",
      label: { en: "German Brief", ar: "موجز ألماني" },
      accessor: "brief_de",
      type: "editor",
      required: true,
    },
  ],
});
