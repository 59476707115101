import actions from "./actions";

const reducers = {
  [actions.exportTechnicians.request]: (state, action) => {
    state.loading = true;
  },
  [actions.exportTechnicians.success]: (state, action) => {
    state.loading = false;
  },
  [actions.exportTechnicians.failure]: (state, action) => {
    state.loading = false;
  },

  [actions.resetPassword.request]: (state, action) => {
    state.loading = true;
  },
  [actions.resetPassword.success]: (state, action) => {
    state.loading = false;
  },
  [actions.resetPassword.failure]: (state, action) => {
    state.loading = false;
  },

  [actions.toggleArchive.request]: (state, action) => {
    state.loading = true;
  },
  [actions.toggleArchive.success]: (state, action) => {
    state.loading = false;
  },
  [actions.toggleArchive.failure]: (state, action) => {
    state.loading = false;
  },
};

export default reducers;
