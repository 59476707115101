import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "warrantyStatusEvent",
  pluralName: "warrantyStatusEvents",
  pathName: "warranty_status_events",
  endpoint: "/admins/warranty_status_events",
  serverName: "event",
  serverPluralName: "events",
  displayName: "warranty status events",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  isPassive: true,
});
