import { Entity } from "@thewebops/admin-panel";
import ErrorMessageHandle from "../components/errorMessageHandle";
import { ROLES } from "utils/constants";
import moment from "moment";
const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "exportFile",
  pluralName: "export_files",
  pathName: "export_files",
  endpoint: "/admins/export_files",
  serverName: "export_file",
  serverPluralName: "export_files",
  displayName: { en: "Export Files", ar: "إستخراج الملفات" },
  showRowActions: true,
  showSearch: true,
  titleField: "file_data",
  entityIdentifier: "id",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  authorizedRoles: {
    entity: [superAdmin, salesRep, crSectionHead, masterDataOwner],
    create: [superAdmin],
    edit: [superAdmin],
    delete: [superAdmin],
  },
  rowActions: [
    {
      label: "Download Excel",
      authorizedRoles: [superAdmin, salesRep, crSectionHead, masterDataOwner],
      active: (dataObject) => dataObject && dataObject.file_url,
      customFunction: (dispatch, item) => {
        var win = window.open(item.file_url, "_blank");
        win.focus();
      },
    },
  ],

  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "file_data",
      label: { en: "File Name", ar: "اسم الملف" },
      accessor: (dataObject) => (dataObject.file_data ? JSON.parse(dataObject.file_data).metadata.filename : null),
      inList: true,
      type: "string",
    },
    {
      identifier: "file_type",
      label: { en: "File Type", ar: "نوع الملف" },
      accessor: "file_type",
      inList: true,
      type: "string",
    },
    {
      identifier: "status",
      label: { en: "Status", ar: "الحالة" },
      accessor: "status",
      inList: true,
      type: "select",
      options: [
        { value: "pending", label: { en: "Pending", ar: "قيد الانتظار" } },
        { value: "failed", label: { en: "Failed", ar: "فشل" } },
        { value: "success", label: { en: "Success", ar: "نجح" } },
      ],
    },
    {
      identifier: "created_at",
      label: { en: "Exported Date", ar: "تاريخ الاستخراج" },
      accessor: (dataObject) => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "string",
    },
    {
      identifier: "error_message",
      label: { en: "Errors", ar: "الاخطاء" },
      accessor: "error_message",
      inList: true,
      component: ErrorMessageHandle,
    },
  ],
  filters: [
    {
      label: "Status",
      name: "status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "pending", label: { en: "Pending", ar: "قيد الانتظار" } },
          { value: "failed", label: { en: "Failed", ar: "فشل" } },
          { value: "success", label: { en: "Success", ar: "نجح" } },
        ],
      },
    },
  ],
});
