import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "jobPointsStatusEvent",
  pluralName: "jobPointsStatusEvents",
  pathName: "points_status_events",
  endpoint: "/admins/jobs/points_status_events",
  serverName: "event",
  serverPluralName: "events",
  displayName: "job points status events",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  isPassive: true,
});
