import { Entity } from "@thewebops/admin-panel";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { push } from "connected-react-router";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  accounant,
} = ROLES;

export default new Entity({
  name: "technician",
  pluralName: "technician",
  pathName: "technicians",
  endpoint: "/admins/technicians",
  serverName: "technician",
  serverPluralName: "technicians",
  displayName: { en: "Technicians", ar: "الفنيين" },
  showSearch: true,
  allowCreate: false,
  allowEdit: true,
  allowDelete: false,
  showRowActions: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  titleField: "personal_code",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      accounant,
    ],
    create: [superAdmin, applicationSupport, callCenterSupervisor, callCenterAgent, crSpecialist, crSectionHead],
    edit: [
      superAdmin,
      applicationSupport,
      callCenterSupervisor,
      callCenterAgent,
      crSpecialist,
      crSectionHead,
      accounant,
    ],
    delete: [superAdmin, applicationSupport, crSpecialist, crSectionHead],
  },
  rowActions: [
    {
      label: { en: "Send OTP", ar: "ارسل رمز المرور " },
      name: "resetPassword",
      authorizedRoles: [superAdmin],
    },

    {
      label: { en: "Archive", ar: "حظر الحساب" },
      name: "toggleArchive",
      requireConfirmation: true,
      confirmationMessage: "Are you sure you want to archive this technician?",
      active: (dataObject) => !dataObject.discarded_at,
      authorizedRoles: [superAdmin],
    },

    {
      label: { en: "Unarchive", ar: "فك الحظر" },
      name: "toggleArchive",
      requireConfirmation: true,
      confirmationMessage: "Are you sure you want to unarchive this technician?",
      active: (dataObject) => dataObject.discarded_at,
      authorizedRoles: [superAdmin],
    },
  ],
  globalActions: [
    {
      label: "Export",
      name: "exportTechnicians",
      authorizedRoles: [superAdmin],
    },
    {
      label: { en: "Invite", ar: "ارسل دعوة" },
      name: "inviteTechnician",
      authorizedRoles: [superAdmin, callCenterSupervisor, callCenterAgent, crSectionHead],
      customFunction: (dispatch, item) => {
        dispatch(push("invite_technician"));
      },
    },
  ],
  useFormData: true,
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "avatar",
      label: { en: "avatar", ar: "رقم البطاقة" },
      accessor: "avatar",
      type: "image",
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "national_id_no",
      label: { en: "National id", ar: "رقم البطاقة" },
      accessor: "national_id_no",
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "phone_number",
      label: { en: "Phone number", ar: "رقم الهاتف" },
      accessor: "phone_number",
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "national_id_front_image",
      label: { en: "national id front image", ar: "صورة البطاقة الأمامية" },
      accessor: "national_id_front_image",
      type: "image",
    },
    {
      identifier: "national_id_back_image",
      label: { en: "national_id_back_image", ar: "صورة البطاقة الخلفية" },
      accessor: "national_id_back_image",
      type: "image",
    },
    {
      identifier: "personal_code",
      label: { en: "Personal code", ar: "الرقم الشخصي" },
      accessor: "personal_code",
      inList: true,
      type: "string",
      hideInEdit: true,
      hideInCreate: true,
    },
    {
      identifier: "living_area_id",
      label: { en: "Living Area", ar: "المنطقة السكنية" },
      formAccessor: (dataObject) => dataObject.living_area?.id,
      accessor: (dataObject) => dataObject.living_area?.name_en,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "livingArea",
        labelField: "name_en",
        valueField: "id",
      },
      hideInEdit: true,
    },
    {
      identifier: "points",
      label: "Points",
      accessor: "points",
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "gathered_points",
      label: "Gathered Points",
      accessor: "gathered_points",
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "points",
      label: "Points",
      accessor: "points",
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "gathered_points",
      label: "Gathered Points",
      accessor: "gathered_points",
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "do_pay_account_number",
      label: "Do Pay Account Number",
      accessor: "do_pay_account_number",
      type: "string",
    },
    {
      identifier: "do_pay_expires_at",
      label: "Do Pay Account Expiry Date",
      accessor: "do_pay_expires_at",
      type: "date",
    },
    {
      identifier: "has_dopay",
      label: "Has Dopay",
      accessor: "has_dopay",
      type: "string",
      hideInEdit: true,
      inList: true,
    },
    {
      identifier: "governorate_id",
      label: { en: "Governorate", ar: "المحافظة" },
      formAccessor: (dataObject) => dataObject.governorate?.id,
      accessor: (dataObject) => dataObject.governorate?.name_en,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "governorate",
        labelField: "name_en",
        valueField: "id",
      },
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "agent_id",
      label: "Agent",
      formAccessor: (dataObject) => dataObject.agent?.id,
      accessor: (dataObject) => dataObject.agent?.name,
      inList: true,
      type: "reference",
      optionsEntity: {
        name: "agent",
        labelField: "name",
        valueField: "id",
        filterParams: [{ name: "archive_value", value: "false", type: "both" }],
      },
    },
    {
      identifier: "zone_ids",
      label: "Zones",
      multiple: true,
      type: "reference",
      accessor: "zones",
      optionsEntity: {
        name: "zone",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "national_id_front_image",
      label: { en: "National Id Front Image", ar: "الصورة الأمامية للهوية الوطنية" },
      accessor: "national_id_front_image",
      type: "image",
      required: true,
    },
    {
      identifier: "national_id_back_image",
      label: { en: "National Id Back Image", ar: "صورة خلفية الهوية الوطنية" },
      accessor: "national_id_back_image",
      type: "image",
      required: true,
    },

    {
      identifier: "deleted",
      label: { en: "Deleted", ar: "محذوف" },
      accessor: (dataObject) => (dataObject.discarded_at ? "True" : "False"),
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
    },
  ],
  filters: [
    {
      label: { en: "Deleted", ar: "نوع الهدية" },
      name: "deleted",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: true, label: { en: "True", ar: "الصنف" } },
          { value: false, label: { en: "False", ar: "نقدي" } },
        ],
      },
    },
  ],
});
