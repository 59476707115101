import { Entity } from "@thewebops/admin-panel";
import statusesSection from "./sections/statuses";
import { ROLES } from "utils/constants";

const { superAdmin, crSpecialist, crSectionHead } = ROLES;

export default new Entity({
  name: "technician_gift",
  pluralName: "technician_gift",
  pathName: "technician_gifts",
  endpoint: "/admins/technician_gifts",
  serverName: "technician_gift",
  serverPluralName: "technician_gifts",
  displayName: { en: "Redeemed Gifts", ar: "الهدايا المستردة" },
  allowEdit: true,
  allowCreate: false,
  allowDelete: false,
  showRowActions: true,
  showSearch: true,
  titleField: "gift_id",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [superAdmin, crSectionHead, crSpecialist],
    create: [superAdmin],
    edit: [superAdmin, crSectionHead, crSpecialist],
    delete: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "technician_id",
      label: { en: "Technician", ar: "الفني" },
      formAccessor: (dataObject) => dataObject.technician?.id,
      accessor: (dataObject) => dataObject.technician?.name,
      inList: true,
      type: "reference",
      required: true,
      hideInEdit: true,
      optionsEntity: {
        name: "technician",
        labelField: (dataObject) => {
          return `${dataObject.phone_number} -- ${dataObject.name}`;
        },
        valueField: "id",
      },
    },
    {
      identifier: "gift_id",
      label: { en: "Gift", ar: "الهدية" },
      formAccessor: (dataObject) => dataObject.gift?.id,
      accessor: (dataObject) => dataObject.gift?.name,
      inList: true,
      type: "reference",
      hideInEdit: true,
      optionsEntity: {
        name: "gift",
        labelField: "name",
        valueField: "id",
        filterParams: [{ name: "homeowner_id", value: ["state", "homeowner_id"], type: "both" }],
      },
    },
    {
      identifier: "gift_type",
      label: { en: "Gift Type", ar: "نوع الهدية" },
      formAccessor: (dataObject) => dataObject.gift?.id,
      accessor: (dataObject) => dataObject.gift?.gift_type,
      type: "reference",
      inList: true,
      hideInEdit: true,
      optionsEntity: {
        name: "gift",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "quantity",
      label: { en: "Quantity", ar: "كمية" },
      accessor: "quantity",
      type: "number",
      required: true,
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "do_pay_account_number",
      label: { en: "Do Pay Account Number", ar: "رقم حساب الدوباي" },
      accessor: "do_pay_account_number",
      type: "string",
      required: true,
      inList: true,
      hideInEdit: true,
    },
    ...statusesSection,
    {
      identifier: "reason_of_rejection",
      label: "Reason of rejection",
      accessor: "reason_of_rejection",
      type: "string",
      inShow: true,
      dependsOn: "status_event",
      dependsOnValue: [
        "return_by_technician",
        "return_by_agent",
        "cancel",
        "returned_by_technician",
        "returned_by_agent",
        "canceled",
      ],
      required: true,
    },
    {
      identifier: "agent_id",
      label: { en: "Agent", ar: "عميل" },
      formAccessor: (dataObject) => dataObject.agent?.id,
      accessor: (dataObject) => dataObject.agent?.name,
      inList: true,
      type: "reference",
      optionsEntity: {
        name: "agent",
        labelField: "name",
        valueField: "id",
      },
    },
  ],
  filters: [
    {
      label: { en: "Gift Type", ar: "نوع الهدية" },
      name: "gift_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "cash", label: { en: "Cash", ar: "نقدي" } },
          { value: "item", label: { en: "Item", ar: "منتج" } },
          { value: "service", label: { en: "Service", ar: "خدمة" } },
        ],
      },
    },
    {
      label: { en: "Technician", ar: "الفني" },
      name: "technician_id",
      type: "select",
      multiple: true,
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "technician",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
