import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin, masterDataOwner,
} = ROLES;

export default new Entity({
  name: "component",
  pluralName: "components",
  pathName: "component",
  endpoint: "/admins/components",
  serverName: "component",
  serverPluralName: "components",
  displayName: { en: "Component", ar: "العناصر" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  titleField: "name",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [superAdmin, masterDataOwner],
    create: [superAdmin, masterDataOwner],
    edit: [superAdmin, masterDataOwner],
    delete: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name_en",
      label: {en:"English Name", ar: "الاسم بالانجليزية"},
      accessor: "name_en",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "name_ar",
      label: {en: "Arabic Name", ar: "الاسم بالعربية"},
      accessor: "name_ar",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "name_de",
      label: {en: "German Name", ar: "الاسم الألماني"},
      accessor: "name_de",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "description_en",
      label: { en: "English Description", ar: "الوصف الانجليزي" },
      accessor: "description_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_ar",
      label: { en: "Arabic Description", ar: "الوصف العربي" },
      accessor: "description_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_de",
      label: { en: "German Description", ar: "الوصف الألماني" },
      accessor: "description_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "model_id",
      label: { en: "Model id", ar: "الرقم التعريفي للنموذج" },
      accessor: "model_id",
      type: "string",
      required: true,
    },
    {
      identifier: "product_ids",
      label: { en: "Parts", ar: "القطع" },
      lgCol: 9,
      mdCol: 9,
      accessor: (dataObject) => (dataObject.products ? dataObject.products : []),
      type: "reference",
      multiple: true,
      isSearchable: true,
      required: true,
      optionsEntity: {
        name: "product",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "price",
      label: { en: "Price", ar: "السعر" },
      inList: true,
      accessor: "price",
      type: "number",
      required: true,
    },
  ],
});
