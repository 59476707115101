import { takeLatest, call, put, select } from "redux-saga/effects";
import { request, showNotification, filtersToAttributes, getEntity } from "@thewebops/admin-panel";
import i18n from "i18next";
import actions from "./actions";

function* exportPointsTransactions(action, state) {
  const { entity, customEntityName } = action.payload;
  const { success, failure } = actions.exportPointsTransactions;
  try {
    const storeEntity = yield select(getEntity, customEntityName || entity.pluralName);
    let { points_type, consumed, technician_id } = {
      ...filtersToAttributes(entity, { ...storeEntity, ...action.payload }),
    };
    yield call(request, {
      url: "admins/export_files",
      data: {
        file_type: "points_transactions",
        points_type,
        consumed,
        technician_id,
      },
      method: "post",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "success", i18n.t("notifications:export"));
  } catch (error) {
    yield put(failure());
    showNotification(null, "error");
  }
}

function* watchExportTransactionsSaga() {
  const { request } = actions.exportPointsTransactions;

  yield takeLatest(request, exportPointsTransactions);
}

export default [watchExportTransactionsSaga];
