import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  masterDataOwner,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  warehouseClerk,
  afterSalesSectionHead,
  technicalSales,
  accounant,
  crWarehouse,
  purchaser,
} = ROLES;

export default new Entity({
  name: "report",
  pluralName: "report",
  pathName: "reports",
  endpoint: "/admins/reports",
  serverName: "report",
  serverPluralName: "reports",
  displayName: { en: "Reports", ar: "التقارير" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  titleField: "title",
  entityIdentifier: "id",
  customErrorKeys: ["media_items"],
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      crWarehouse,
      purchaser,
      salesRep,
      crSpecialist,
      crSectionHead,
      warehouseClerk,
      afterSalesSectionHead,
      technicalSales,
      accounant,
      masterDataOwner,
    ],
    create: [superAdmin, applicationSupport, marketingOfficer, masterDataOwner],
    edit: [superAdmin, applicationSupport, marketingOfficer, masterDataOwner],
    delete: [superAdmin, applicationSupport, marketingOfficer],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "image",
      label: "Image",
      accessor: "image",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Image", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: true,
    },
    {
      identifier: "title",
      label: { en: "Title", ar: "الاسم" },
      accessor: "title",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "title_en",
      label: { en: "English Title", ar: "العنوان بالانجليزية" },
      accessor: "title_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "title_ar",
      label: { en: "Arabic Title", ar: "العنوان بالعربية" },
      accessor: "title_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "title_de",
      label: { en: "German Title", ar: "العنوان بالالمانية" },
      accessor: "title_de",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "description_en",
      label: { en: "English Description", ar: "الوصف الانجليزي" },
      accessor: "description_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_ar",
      label: { en: "Arabic Description", ar: "الوصف العربي" },
      accessor: "description_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_de",
      label: { en: "German Description", ar: "الوصف الألماني" },
      accessor: "description_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "featured",
      label: { en: "Featured", ar: "رئيسية" },
      accessor: "featured",
      type: "radio",
      inList: true,
      required: true,
      options: [
        { value: true, label: { en: "Yes", ar: "نعم" } },
        { value: false, label: { en: "No", ar: "لا" } },
      ],
    },
    {
      identifier: "seo_featured_image",
      label: "Seo Featured Image",
      accessor: "seo_featured_image",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Image", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
    },
    {
      identifier: "seo_description",
      label: "Seo description",
      accessor: "seo_description",
      inList: false,
      type: "string",
      required: false,
    },
    {
      identifier: "seo_keywords",
      label: "Seo keywords",
      accessor: "seo_keywords",
      inList: false,
      type: "string",
      required: false,
    },

    {
      identifier: "report_file",
      label: { en: "Report file", ar: "ملف التقرير" },
      type: "file",
      hideInEdit: true,
      hideInCreate: true,
      accessor: "report_file",
    },
  ],
});
