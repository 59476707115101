import actions from "./actions";

const reducers = {
  [actions.pointsTierGifts.request]: (state, action) => {
    state.loading = true;
    state.errors = {};
  },
  [actions.pointsTierGifts.success]: (state, action) => {
    state.loading = false;
    state.data = action.payload;
  },
  [actions.pointsTierGifts.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
};

export default reducers;
