import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin, applicationSupport, hr } = ROLES;

export default new Entity({
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  endpoint: "/admins/admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: { en: "Admins", ar: "المشرفين" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: (object) => object.role !== "super_admin",
  showRowActions: true,
  customCreateEndpoint: "/admins/invitations",
  titleField: "name",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [superAdmin, applicationSupport, hr],
    create: [superAdmin, applicationSupport, hr],
    edit: [superAdmin, applicationSupport, hr],
    delete: [superAdmin, applicationSupport, hr],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "email",
      label: { en: "Email", ar: "البريد الإلكتروني" },
      accessor: "email",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "role",
      label: { en: "Role", ar: "الوظيفة" },
      accessor: "role",
      inList: true,
      type: "select",
      options: [
        { value: "super_admin", label: { en: "Super Admin", ar: "مشرف المتميز" } },
        { value: "application_support", label: { en: "Application Support", ar: "مسئول دعم التطبيق" } },
        { value: "hr", label: { en: "Human Resource", ar: "مسئول الموارد البشرية" } },
        { value: "marketing_officer", label: { en: "Marketing Officer", ar: "مسئول التسويق" } },
        { value: "call_center_supervisor", label: { en: "Call Center Supervisor", ar: "مشرف خدمة العملاء" } },
        { value: "call_center_agent", label: { en: "Call Center Agent", ar: "موظف خدمة العملاء" } },
        { value: "sales_rep", label: { en: "Sales Rep", ar: "مندوب مبيعات" } },
        { value: "cr_section_head", label: { en: "CR Section Head", ar: "مدير علاقات العملاء" } },
        { value: "cr_specialist", label: { en: "CR Specialist", ar: "أخصائي علاقات العملاء" } },
        { value: "cr_warehouse", label: { en: "CR Warehouse", ar: "مسئول علاقات العملاء في المخازن" } },
        { value: "warehouse_clerk", label: { en: "Warehouse Clerk", ar: "أخصائي المخازن" } },
        { value: "master_data_owner", label: { en: "Master Data Owner", ar: "مسئول البيانات الرئيسية" } },
        { value: "accounant", label: { en: "Accountant", ar: "محاسب" } },
        { value: "purchaser", label: { en: "Purchaser", ar: "مسئول المشتريات" } },
        {
          value: "after_sales_section_head",
          label: { en: "After sales Section Head", ar: "مدير خدمات ما بعد البيع" },
        },
        { value: "technical_sales", label: { en: "Technical Sales", ar: "موظف مبيعات تقني" } },
      ],
      required: true,
    },
    {
      identifier: "locale",
      label: { en: "Locale", ar: "اللغة المفضلة" },
      accessor: "locale",
      inList: true,
      type: "select",
      options: [
        { value: "en", label: { en: "English", ar: "الإنجليزية" } },
        { value: "ar", label: { en: "Arabic", ar: "العربية " } },
      ],
      required: true,
    },
  ],
  filters: [
    {
      label: "Role",
      name: "role",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "super_admin", label: { en: "Super Admin", ar: "مشرف المتميز" } },
          { value: "application_support", label: { en: "Application Support", ar: "مسئول دعم التطبيق" } },
          { value: "hr", label: { en: "Human Resource", ar: "مسئول الموارد البشرية" } },
          { value: "marketing_officer", label: { en: "Marketing Officer", ar: "مسئول التسويق" } },
          { value: "call_center_supervisor", label: { en: "Call Center Supervisor", ar: "مشرف خدمة العملاء" } },
          { value: "call_center_agent", label: { en: "Call Center Agent", ar: "موظف خدمة العملاء" } },
          { value: "sales_rep", label: { en: "Sales Rep", ar: "مندوب مبيعات" } },
          { value: "cr_section_head", label: { en: "CR Section Head", ar: "مدير علاقات العملاء" } },
          { value: "cr_specialist", label: { en: "CR Specialist", ar: "أخصائي علاقات العملاء" } },
          { value: "cr_warehouse", label: { en: "CR Warehouse", ar: "مسئول علاقات العملاء في المخازن" } },
          { value: "warehouse_clerk", label: { en: "Warehouse Clerk", ar: "أخصائي المخازن" } },
          { value: "master_data_owner", label: { en: "Master Data Owner", ar: "مسئول البيانات الرئيسية" } },
          { value: "accounant", label: { en: "Accountant", ar: "محاسب" } },
          { value: "purchaser", label: { en: "Purchaser", ar: "مسئول المشتريات" } },
          {
            value: "after_sales_section_head",
            label: { en: "After sales Section Head", ar: "مدير خدمات ما بعد البيع" },
          },
          { value: "technical_sales", label: { en: "Technical Sales", ar: "موظف مبيعات تقني" } },
        ],
      },
    },
  ],
});
