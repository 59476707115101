const statusesSection = [
  {
    identifier: "status",
    label: { en: "status", ar: "الحالة" },
    accessor: "status",
    lgCol: 7,
    mdCol: 7,
    type: "string",
    inList: true,
    disabled: true,
    hideInCreate: true,
    useHumanizer: true,
  },
  {
    identifier: "status_event",
    label: { en: "Status event", ar: "حالة الشحنة" },
    accessor: (dataObject) =>
      dataObject.technician_gift_status_event ? dataObject.technician_gift_status_event.value : null,
    formAccessor: (dataObject) =>
      dataObject.technician_gift_status_event ? dataObject.technician_gift_status_event.value : null,
    lgCol: 7,
    mdCol: 7,
    useHumanizer: true,
    hideInCreate: true,
    hideInShow: true,
    type: "reference",
    optionsEntity: {
      name: "technicianGiftStatusEvent",
      labelField: "label",
      valueField: "value",

      filterParams: [
        {
          name: "technician_gift_id",
          value: ["params", "id"],
          type: "edit",
        },
        {
          name: "state_column_name",
          value: "status",
          type: "edit",
        },
      ],
    },
  },
];

export default statusesSection;
