import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";
import ErrorMessageHandle from "../../components/errorMessageHandle";

const {
  superAdmin,
  applicationSupport,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
} = ROLES;

export default new Entity({
  name: "import_sheet",
  pluralName: "import_sheets",
  pathName: "import_sheets",
  endpoint: "/admins/import_sheets",
  serverName: "import_sheet",
  serverPluralName: "import_sheets",
  displayName: { en: "Imported Sheets", ar: "الملفات المحملة" },
  showRowActions: false,
  showSearch: true,
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  entityIdentifier: "id",
  titleField: "file_data",
  authorizedRoles: {
    entity: [superAdmin, applicationSupport, salesRep, crSpecialist, crSectionHead, crWarehouse, masterDataOwner],
    create: [superAdmin],
    edit: [superAdmin],
    delete: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "file_data",
      label: { en: "File Name", ar: "اسم الملف" },
      accessor: (dataObject) => JSON.parse(dataObject.file_data).metadata.filename,
      inList: true,
      usedToShow: true,
      type: "string",
    },
    {
      identifier: "sheet_type",
      label: { en: "File Type", ar: "نوع الملف" },
      accessor: "sheet_type",
      inList: true,
      type: "string",
    },
    {
      identifier: "status",
      label: { en: "Status", ar: "الحالة" },
      accessor: "status",
      inList: true,
      type: "select",
      options: [
        { value: "pending", label: { en: "Pending", ar: "قيد الانتظار" } },
        { value: "failed", label: { en: "Failed", ar: "فشل" } },
        { value: "success", label: { en: "Success", ar: "نجح" } },
      ],
    },
    {
      identifier: "error_message",
      label: { en: "Errors", ar: "الاخطاء" },
      accessor: "error_message",
      inList: false,
      component: ErrorMessageHandle,
    },
  ],
  filters: [
    {
      label: "Type",
      name: "sheet_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "product_sheet", label: { en: "Product", ar: "المنتج" } },
          { value: "order_sheet", label: { en: "Order", ar: "الطلب" } },
        ],
      },
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "pending", label: { en: "Pending", ar: "قيد الانتظار" } },
          { value: "failed", label: { en: "Failed", ar: "فشل" } },
          { value: "success", label: { en: "Success", ar: "نجح" } },
        ],
      },
    },
  ],
});
