import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "cmsBrand",
  pluralName: "cmsBrands",
  pathName: "cms_brand",
  endpoint: "/admins/cms_brands",
  serverName: "cms_brand",
  serverPluralName: "cms_brands",
  displayName: { en: "CMS Brands", ar: "العلامات التجارية" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  titleField: "name",
  customErrorKeys: ["media_items"],
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, marketingOfficer, masterDataOwner],
    edit: [superAdmin, applicationSupport, marketingOfficer, masterDataOwner],
    delete: [superAdmin, applicationSupport],
  },

  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "brief_en",
      label: { en: "English Brief", ar: "موجز إنجليزي" },
      accessor: "brief_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "brief_ar",
      label: { en: "Arabic Brief", ar: "موجز عربي" },
      accessor: "brief_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "brief_de",
      label: { en: "German Brief", ar: "موجز ألماني" },
      accessor: "brief_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "seo_featured_image",
      label: "Seo Featured Image",
      accessor: "seo_featured_image",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Image", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: false,
    },

    {
      identifier: "logo",
      label: "Logo",
      accessor: "logo",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Image", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: true,
    },

    {
      identifier: "video",
      label: "Video",
      accessor: "video",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "video",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Video", ar: "الفيديو" },
          type: "video",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: true,
    },
    {
      identifier: "top_description_en",
      label: { en: "English Top Description", ar: "الوصف الأعلى بالإنجليزية" },
      accessor: "top_description_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "top_description_ar",
      label: { en: "Arabic Top Description", ar: "الوصف الأعلى بالعربية" },
      accessor: "top_description_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "top_description_de",
      label: { en: "German Top Description", ar: "الوصف الأعلى بالالمانية" },
      accessor: "top_description_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "bottom_description_en",
      label: { en: "English Bottom Description", ar: "الوصف السفلي بالانجليزية" },
      accessor: "bottom_description_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "bottom_description_ar",
      label: { en: "Arabic Bottom Description", ar: "الوصف السفلي بالعربية" },
      accessor: "bottom_description_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "bottom_description_de",
      label: { en: "German Bottom Description", ar: "الوصف السفلي بالالمانية" },
      accessor: "bottom_description_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "website",
      label: { en: "Website", ar: "الموقع إلكتروني" },
      accessor: "website",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "seo_description",
      label: "Seo description",
      accessor: "seo_description",
      inList: true,
      type: "string",
      required: false,
    },
    {
      identifier: "seo_keywords",
      label: "Seo keywords",
      accessor: "seo_keywords",
      inList: true,
      type: "string",
      required: false,
    },
  ],
});
