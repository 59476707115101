import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "faq",
  pluralName: "faqs",
  pathName: "faq",
  endpoint: "/admins/faqs",
  serverName: "faq",
  serverPluralName: "faqs",
  displayName: { en: "FAQ", ar: "الأسئلة الشائعة" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  titleField: "question",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, marketingOfficer, callCenterSupervisor],
    edit: [superAdmin, applicationSupport, marketingOfficer],
    delete: [superAdmin, applicationSupport],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "question",
      label: { en: "Question", ar: "السؤال" },
      accessor: "question",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "question_en",
      label: { en: "English Question", ar: "السؤال بالانجليزية" },
      accessor: "question_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "question_ar",
      label: { en: "Arabic Question", ar: "السؤال بالعربية" },
      accessor: "question_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "question_de",
      label: { en: "German Question", ar: "السؤال بالالماني" },
      accessor: "question_de",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "answer_en",
      label: { en: "English Answer", ar: "الإجابة الإنجليزية" },
      accessor: "answer_en",
      type: "string",
      required: true,
    },
    {
      identifier: "answer_ar",
      label: { en: "Arabic Answer", ar: "الإجابة العربية" },
      accessor: "answer_ar",
      type: "string",
      required: true,
    },
    {
      identifier: "answer_de",
      label: { en: "German Answer", ar: "إجابة ألمانية" },
      accessor: "answer_de",
      type: "string",
      required: true,
    },
    {
      identifier: "faq_category_id",
      label: { en: "FAQ Category", ar: "فئة الأسئلة الشائعة" },
      formAccessor: (dataObject) => dataObject.faq_category?.id,
      accessor: (dataObject) => dataObject.faq_category?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "faqCategory",
        labelField: "name",
        valueField: "id",
      },
    },
  ],
  filters: [
    {
      label: { en: "FAQs Category", ar: "فئة الأسئلة الشائعة" },
      name: "faq_category_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "faqCategory",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
