import { Entity } from "@thewebops/admin-panel";
import moment from "moment";

export default new Entity({
  name: "pointEvent",
  pluralName: "pointEvents",
  pathName: "point_event",
  endpoint: "/admins/point_events",
  serverName: "point_event",
  serverPluralName: "point_events",
  displayName: { en: "Points Events", ar: "النقاط" },
  allowCreate: true,
  allowEdit: false,
  showRowActions: false,
  titleField: "technician_id",
  entityIdentifier: "id",

  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "event_type",
      label: { en: "Event type", ar: "نوع الحدث" },
      accessor: "event_type",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      type: "select",
      options: [
        { value: "admin", label: { en: "Admin", ar: "مشرف" } },
        { value: "expire", label: { en: "Expire", ar: "انتهت" } },
        { value: "gift", label: { en: "Gift", ar: "هدية" } },
        { value: "job", label: { en: "Job", ar: "مهمة" } },
        { value: "bonus_points", label: { en: "Bonus Points", ar: "نقاط مكافأة" } },
      ],
    },
    {
      identifier: "number_of_points",
      label: { en: "Number of points", ar: "عدد النقاط" },
      accessor: "number_of_points",
      inList: true,
      type: "number",
      required: true,
    },
    {
      identifier: "expiry_date",
      label: { en: "Expiry date", ar: "تاريخ الانتهاء" },
      accessor: (dataObject) => (dataObject.expiry_date ? moment(dataObject.expiry_date).format(" MMMM YYYY") : null),
      inList: false,
      type: "date",
      required: true,
      hideInShow: true,
      disablePast: true,
      excludeDay: true,
      sendFullDate: true,
    },
    {
      identifier: "technician_id",
      label: { en: "Technician", ar: "الفني" },
      type: "reference",
      formAccessor: (dataObject) => dataObject.technician?.id,
      accessor: (dataObject) => dataObject.technician?.personal_code,
      isSearchable: true,
      optionsEntity: {
        name: "technician",
        labelField: (dataObject) => `${dataObject.personal_code} - ${dataObject.name}`,
        valueField: "id",
        displayAsLink: true,
      },
      inList: true,
      required: true,
    },
  ],
  filters: [
    {
      label: { en: "Technician", ar: "الفني" },
      name: "technician_id",
      type: "select",
      multiple: true,
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "technician",
          labelField: "name",
          valueField: "id",
        },
      },
    },

    {
      label: { en: "Event type", ar: "نوع الحدث" },
      name: "event_type",
      type: "select",
      multiple: false,
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "admin", label: { en: "admin", ar: "مشرف" } },
          { value: "expire", label: { en: "expire", ar: "انتهاء" } },
          { value: "gift", label: { en: "gift", ar: "هدية" } },
          { value: "job", label: { en: "job", ar: "وظيفة" } },
          { value: "bonus_points", label: { en: "bonus_points", ar: "نقاط مكافأة" } },
        ],
      },
    },
  ],
});
