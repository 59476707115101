import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "solution",
  pluralName: "solutions",
  pathName: "solution",
  endpoint: "/admins/solutions",
  serverName: "solution",
  serverPluralName: "solutions",
  displayName: { en: "Solutions", ar: "الحلول" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  titleField: "name",
  customErrorKeys: ["media_items"],
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, marketingOfficer, technicalSales, masterDataOwner],
    edit: [superAdmin, applicationSupport, marketingOfficer, technicalSales, masterDataOwner],
    delete: [superAdmin, applicationSupport],
  },
  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "pdf_file",
      label: "Pdf file",
      accessor: "pdf_file",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "pdf",
        },
        {
          identifier: "id",
          label: { en: "ID", ar: "الرقم التسلسلي" },
          accessor: "id",
          hideInShow: true,
        },
        {
          useGallery: true,
          identifier: "file",
          label: "pdf",
          type: "pdf",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: false,
    },
    {
      identifier: "seo_featured_image",
      label: { en: "Seo Featured Image", ar: "الصور الدالة seo" },
      accessor: "seo_featured_image",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Image", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: false,
    },
    {
      identifier: "images_attributes",
      label: { en: "Images", ar: "الصور" },
      accessor: "images",
      type: "dynamic",
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          identifier: "id",
          label: { en: "ID", ar: "الرقم التسلسلي" },
          accessor: "id",
          hideInShow: true,
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Image", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: true,
    },
    {
      identifier: "video",
      label: { en: "Video", ar: "فيديو" },
      accessor: "video",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "video",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Video", ar: "الفيديو" },
          type: "video",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم بالانجليزية" },
      accessor: "name_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم بالعربية" },
      accessor: "name_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_de",
      label: { en: "Deutsch Name", ar: "الاسم الألماني" },
      accessor: "name_de",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "specifications_en",
      label: { en: "English Specifications", ar: "المواصفات بالإنجليزية" },
      accessor: "specifications_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "specifications_ar",
      label: { en: "Arabic Specifications", ar: "المواصفات بالعربية" },
      accessor: "specifications_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "specifications_de",
      label: { en: "Deutsch Specifications", ar: "المواصفات بالألمانية" },
      accessor: "specifications_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_en",
      label: { en: "English Description", ar: "الوصف الانجليزي" },
      accessor: "description_en",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_ar",
      label: { en: "Arabic Description", ar: "الوصف العربي" },
      accessor: "description_ar",
      type: "editor",
      required: true,
    },
    {
      identifier: "description_de",
      label: { en: "Deutsch Description", ar: "الوصف الألماني" },
      accessor: "description_de",
      type: "editor",
      required: true,
    },
    {
      identifier: "solution_category_id",
      label: { en: "Solution Category", ar: "فئة الحل" },
      formAccessor: (dataObject) => dataObject.solution_category?.id,
      accessor: (dataObject) => dataObject.solution_category?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "solutionCategory",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "offer_id",
      label: { en: "Offer in percentage", ar: "العرض بالنسبة المئوية" },
      formAccessor: (dataObject) => dataObject.offer?.id,
      accessor: (dataObject) => dataObject.offer?.discount_percentage,
      inList: true,
      type: "reference",
      optionsEntity: {
        name: "offer",
        labelField: "discount_percentage",
        valueField: "id",
      },
    },

    {
      identifier: "seo_description",
      label: { en: "Seo description", ar: "وصف seo" },
      accessor: "seo_description",
      inList: false,
      type: "string",
      required: false,
    },
    {
      identifier: "seo_keywords",
      label: { en: "Seo Keywords", ar: "الكلمات الدالة seo" },
      accessor: "seo_keywords",
      inList: false,
      type: "string",
      required: false,
    },
  ],
  filters: [
    {
      label: { en: "Solution Category", ar: "فئة الحل" },
      name: "solution_category_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "solutionCategory",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
