import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "offer",
  pluralName: "offers",
  pathName: "offers",
  endpoint: "/admins/offers",
  serverName: "offer",
  serverPluralName: "offers",
  displayName: { en: "Offers", ar: "العروض" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  titleField: "name",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, masterDataOwner],
    edit: [superAdmin, applicationSupport, masterDataOwner],
    delete: [superAdmin, applicationSupport, masterDataOwner],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      type: "string",
      inList: true,
      usedToShow: true,
    },
    {
      identifier: "solution_ids",
      label: { en: "Solutions", ar: "الحلول" },
      multiple: true,
      type: "reference",
      accessor: "solutions",
      optionsEntity: {
        name: "solution",
        labelField: "name_en",
        valueField: "id",
      },
    },
    {
      identifier: "cms_product_ids",
      label: { en: "Cms Products", ar: "المنتجات" },
      multiple: true,
      type: "reference",
      accessor: "cms_products",
      optionsEntity: {
        name: "cmsProduct",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "start_date",
      label: { en: "Start Date", ar: "تاريخ البدء" },
      accessor: "start_date",
      inList: true,
      type: "date",
      required: true,
    },
    {
      identifier: "end_date",
      label: { en: "End Date", ar: "تاريخ الانتهاء" },
      accessor: "end_date",
      inList: true,
      type: "date",
      required: true,
    },
    {
      identifier: "discount_percentage",
      label: { en: "Discount Percentage", ar: "نسبة الخصم" },
      accessor: "discount_percentage",
      inList: true,
      type: "number",
      required: true,
    },
  ],
});
