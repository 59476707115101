import { rtlLanguages, resources } from "translation/i18n";
import logo from "assets/logo.png";
import landing from "assets/landing.jpg";
import fullLogo from "assets/fulllogo.png";
import { API_BASE_URL } from "utils/constants";
import { groups, userDefinedEntities } from "./entities";
import { authConfig, authUserDefinedEntities } from "authEntities";
import { customTheme } from "components/Theme";
import { store } from "./index";
import rootReducer from "./reducers";
import { history } from "configureStore";
import dashboardConfig from "dashboard/index";
import playNotificationSound from "utils/playNotificationSound";

const storeDispatcher = (type) => {
  store.dispatch({ type });
};

const replaceReducer = (adminPanelReducers) => {
  const nextReducer = rootReducer(history, adminPanelReducers);
  store.replaceReducer(nextReducer);
};

const {
  REACT_APP_FIREBASE_KEY = "AIzaSyCtekr_34Rf3Mttk4vtGdB-Ybegs7PTD9g",
  REACT_APP_AUTH_DOMAIN = "hamza-cc0f6.firebaseapp.com",
  REACT_APP_PROJECT_ID = "hamza-cc0f6",
  REACT_APP_STORAGE_BUCKET = "hamza-cc0f6.appspot.com",
  REACT_APP_SENDER_ID = 1032177128185,
  // REACT_APP_DATABASE = "https://lirten-hub.firebaseio.com",
  REACT_APP_FIREBASE_APP_ID = "1:1032177128185:web:d06233a63d993f69e0c828",
  REACT_APP_FIREBASE_VAPID_KEY = "BCKpdGvY0OSaUOnaakgv2FUEqZVonEDk8cKfs1xkPAvSx_5uLHDb5xCd9TCsP-45kTKPwmRh9UgrUza1QaZ8g5k",
} = process.env;

const notificationConfigs = {
  enabled: true,
  baseURL: `${API_BASE_URL}/homeowners`,
  authHeaders: "authHeaders",
  displayAsLink: (notification) => {
    if (notification?.data_object?.import_sheet)
      return `/admin/import_sheets/${notification.data_object.import_sheet.id}`;
  },
  onReceiveNotification: playNotificationSound,
  firebaseConfig: {
    apiKey: REACT_APP_FIREBASE_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    // databaseURL: REACT_APP_DATABASE,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
  },
  theme: {
    notificationLayout: {
      dropDownAnimation: "opacity",
      displayImages: false,
      iconSize: 1.8,
      headerHeight: 62,
      dropDownHeight: 64.4,
      top: 150,
      alignment: -57,
      isIconOutlined: true,
      showArrow: true,
      itemTheme: "hamza",
      showEmptyIcon: true,
    },
    notificationColors: {
      date: "#505050",
      icon: "#000",
      iconHover: "#f5f5f5",
      markAsRead: "#051895",
      markAsReadHover: "#051895",
      scrollBarThumb: "#505050",
      scrollBarTrack: "#f5f5f5",
      unreadNotification: "#051895",
      background: "#F8F8F8",
      noDataMessage: "#B0B0B0",
      //background: "red",
    },
  },
};

export const adminConfigs = {
  languageResources: resources,
  disableLocalization: false,
  rtlLanguages,
  logo,
  landingImage: landing,
  fullLogo,
  url: API_BASE_URL,
  groups,
  userDefinedEntities,
  authUserDefinedEntities,
  authConfig,
  theme: customTheme,
  privateRouteURL: "/admin",
  useGallery: true,
  mediaEntity: "media",
  storeDispatcher,
  replaceReducer,
  dashboardConfig,
  notificationConfigs,
};
