import { currentRoleEqualTo } from "@thewebops/admin-panel";

const tablesSection = [
  {
    identifier: "products",
    label: "Products",
    accessor: "products",
    type: "table",
    lgCol: 12,
    mdCol: 12,
    fields: [
      { identifier: "id", label: "ID", accessor: "id", inList: false },
      { identifier: "article_number", label: "Article #", accessor: "article_number", type: "string", inList: true },
      { identifier: "name", label: "Name", accessor: "name", type: "string", inList: true },
      { identifier: "unit_of_measure", label: "Q/M", accessor: "unit_of_measure", type: "string", inList: true },
      { identifier: "quantity_equivalence", label: "Q/E", accessor: "quantity_equivalence", inList: true },
      {
        identifier: "quantity",
        label: "Delivery",
        accessor: "quantity",
        type: "number",
        inList: true,
      },
      { identifier: "amount", label: "Quantity", accessor: "amount", type: "number", inList: true },
      {
        identifier: "new_barcodes",
        label: "New Barcodes",
        accessor: "new_barcodes",
        type: "number",
        inList: (dataObject) => dataObject.order_type === "normal",
      },
    ],
    tableConfig: {
      name: "draftOrder",
      type: "static",
      // stateIdentifier: () => (currentRoleEqualTo("tester") ? "scan" : undefined),
      stateIdentifier: "scan",
      // editable: () => currentRoleEqualTo("tester"),
      editable: true,
      // authorizedRoles: ["tester"],
      editableFields: ["new_barcodes"],
      import: undefined,
    },
  },
  {
    identifier: "barcode",
    label: "Scanned",
    accessor: "products",
    lgCol: 12,
    mdCol: 12,
    type: "scannableTable",

    // hideInEdit: () => !currentRoleEqualTo("tester"),
    fields: [
      { identifier: "article_number", label: "Article #", accessor: "article_number", type: "string", inList: true },
      { identifier: "name", label: "Name", accessor: "name", type: "string", inList: true },
      { identifier: "scanned", label: "Scanned", accessor: "scanned", type: "number", inList: true },
    ],
    tableConfig: {
      name: "draftOrder",
      type: "static",
      customItemCount: (entries) => entries.reduce((acc, entry) => (acc += entry.scanned), 0),
      filterFunction: (item) => item.scanned !== 0,
      requestConfig: {
        endPoint: (current) => `/admins/draft_orders/${current.id}/product_items`,
        payloadWrapper: "product_item",
      },
      responseConfig: {
        dataAccessor: (dataObject) => dataObject.product,
        errorAccessor: (dataObject) => dataObject.products,
      },
    },
  },
];

export default tablesSection;
