import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "solutionInquiry",
  pluralName: "solutionInquiries",
  pathName: "solution_inquiries",
  endpoint: "/admins/solution_inquiries",
  serverName: "solution_inquiry",
  serverPluralName: "solution_inquiries",
  displayName: { en: "Solution Inquiries", ar: "الإستعلامات عن الحلول" },
  titleField: "name",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, technicalSales, callCenterSupervisor, callCenterAgent, technicalSales],
    edit: [superAdmin, applicationSupport, technicalSales, callCenterSupervisor, callCenterAgent, technicalSales],
    delete: [superAdmin, applicationSupport],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
    },
    {
      identifier: "email",
      label: { en: "Email", ar: "البريد الإلكتروني" },
      accessor: "email",
      inList: true,
      type: "string",
    },
    {
      identifier: "phone_number",
      label: { en: "Phone number", ar: "رقم الهاتف" },
      accessor: "phone_number",
      type: "string",
    },
    {
      identifier: "message",
      label: { en: "Message", ar: "الرسالة" },
      accessor: "message",
      type: "text",
    },
    {
      identifier: "solution_id",
      label: { en: "Solution", ar: "الحل" },
      formAccessor: (dataObject) => dataObject.solution?.id,
      accessor: (dataObject) => dataObject.solution?.name_en,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "solution",
        labelField: "name_en",
        valueField: "id",
      },
    },
    {
      identifier: "solution_category_id",
      label: { en: "Solution Category", ar: "فئة الحل" },
      formAccessor: (dataObject) => dataObject.solution_category?.id,
      accessor: (dataObject) => dataObject.solution_category?.name_en,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "solutionCategory",
        labelField: "name_en",
        valueField: "id",
      },
    },
  ],
  filters: [
    {
      label: { en: "Solution", ar: "الحل" },
      name: "solution_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "solution",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Solution Category", ar: "فئة الحل" },
      name: "solution_category",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "solutionCategory",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
