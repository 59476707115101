import { select } from "redux-saga/effects";

const statusesSection = [
  {
    identifier: "status",
    label: { en: "status", ar: "حالة" },
    accessor: "status",
    lgCol: 6,
    mdCol: 6,
    inList: true,
    disabled: true,
    hideInCreate: true,
    type: "select",
    options: [
      { value: "requested", label: { en: "Requested", ar: "تم الطلب" } },
      { value: "e_warranty_pending", label: { en: "Pending", ar: "الضمان معلق" } },
      { value: "e_warranty_issued", label: { en: "Issued", ar: "تم إصدار الضمان" } },
      { value: "delivered", label: { en: "Delivered", ar: "تم التوصيل" } },
      { value: "canceled", label: { en: "Canceled", ar: "تم الإلغاء" } },
    ],
  },
  {
    identifier: "status_event",
    label: { en: "Status event", ar: "حالة الشحنة" },
    accessor: (dataObject) => (dataObject.warranty_status_event ? dataObject.warranty_status_event.value : null),
    formAccessor: (dataObject) => (dataObject.warranty_status_event ? dataObject.warranty_status_event.value : null),
    lgCol: 12,
    mdCol: 12,
    useHumanizer: true,
    hideInCreate: true,
    hideInShow: true,
    type: "reference",
    optionsEntity: {
      name: "warrantyStatusEvent",
      labelField: "label",
      valueField: "value",

      filterParams: [
        {
          name: "warranty_id",
          value: ["params", "id"],
          type: "edit",
        },
        {
          name: "state_column_name",
          value: "status",
          type: "edit",
        },
      ],
    },
  },
];

export default statusesSection;
