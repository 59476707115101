import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "technicianGiftStatusEvent",
  pluralName: "technicianGiftStatusEvents",
  pathName: "technician_gift_status_events",
  endpoint: "/admins/technician_gifts/status_events",
  serverName: "event",
  serverPluralName: "events",
  displayName: "technician gift status events",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  isPassive: true,
});
