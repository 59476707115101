import { Entity } from "@thewebops/admin-panel";
import ChangesetField from "../../components/ChangesetField";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "product",
  pluralName: "products",
  pathName: "product",
  endpoint: "/admins/products",
  serverName: "product",
  serverPluralName: "products",
  displayName: { en: "Products", ar: "المنتجات" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  titleField: "name",
  customSagas: sagas,
  customReducers: reducers,
  customActions: actions,
  entityIdentifier: "id",
  customErrorKeys: ["media_items"],
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, crSectionHead, masterDataOwner],
    edit: [superAdmin, applicationSupport, crSectionHead, masterDataOwner],
    delete: [superAdmin, applicationSupport, masterDataOwner],
  },
  globalActions: [
    {
      label: { en: "Download import sheet template", ar: "تنزيل نموذج" },
      name: "downloadImportTemplate",
      authorizedRoles: [superAdmin],
    },
  ],
  import: {
    enabled: true,
    extensions: ["xlsx", "csv", "application/vnd.openxmlformats-officedocument"],
    paramName: "file",
    method: "post",
    endpoint: "/admins/products/import_sheets",
    authorizedRoles: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "article_number",
      label: { en: "Article Number", ar: "رقم المقالة" },
      accessor: "article_number",
      type: "string",
      inList: true,
      usedToShow: true,
      required: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "price",
      label: { en: "Price", ar: "السعر" },
      accessor: "price",
      type: "number",
      required: true,
    },
    {
      identifier: "redeemable",
      label: { en: "Redeemable", ar: "قابل للاسترداد" },
      accessor: "redeemable",
      type: "radio",
      defaultValue: false,
      required: true,
      options: [
        {
          value: true,
          label: { en: "True", ar: "نعم" },
        },
        {
          value: false,
          label: { en: "False", ar: "لا" },
        },
      ],
    },
    {
      identifier: "points",
      label: { en: "Points", ar: "النقاط" },
      accessor: "points",
      type: "number",
      required: true,
      dependsOn: "redeemable",
      dependsOnValue: [true],
    },
    {
      identifier: "product_sector_id",
      label: { en: "Product Sector", ar: "قطاع المنتج" },
      formAccessor: (dataObject) => dataObject.product_sector?.id,
      accessor: (dataObject) => dataObject.product_sector?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "productSector",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "brand_id",
      label: { en: "Brand", ar: "الماركة" },
      formAccessor: (dataObject) => dataObject.brand?.id,
      accessor: (dataObject) => dataObject.brand?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "brand",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "image",
      label: "Image",
      accessor: "image",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "type",
          label: "type",
          accessor: "content_type",
          value: "image",
        },
        {
          useGallery: true,
          identifier: "file",
          label: { en: "Logo", ar: "الصورة" },
          type: "image",
          accessor: "file",
          mediaOptions: {},
        },
        {
          identifier: "media_id",
          label: "media",
          accessor: "media_id",
          hideInShow: true,
        },
        {
          identifier: "content_type",
          label: "content_type",
          accessor: "content_type",
          hideInShow: true,
        },
      ],
      required: true,
    },
    {
      identifier: "quantity_measurement",
      label: { en: "Quantity Measurement", ar: "قياس الكمية" },
      type: "radio",
      accessor: (dataObject) => dataObject.quantity_measurement,
      inList: false,
      required: true,
      options: [
        { value: "m", label: { en: "Meters", ar: "أمتار" } },
        { value: "pc", label: { en: "Pieces", ar: "قطع" } },
      ],
    },
    {
      identifier: "quantity_equivalence",
      label: { en: "Quantity Equivalence", ar: "معادلة الكمية" },
      accessor: (dataObject) => dataObject.quantity_equivalence,
      type: "number",
      required: true,
      dependsOn: "quantity_measurement",
      dependsOnValue: ["m"],
      inList: false,
    },
    {
      identifier: "paper_trail/versions",
      accessor: (dataObject) => (dataObject.versions ? dataObject.versions["paper_trail/versions"] : null),
      formAccessor: (dataObject) => (dataObject.versions ? dataObject.versions["paper_trail/versions"] : null),
      label: "History",
      hideInShow: false,
      hideInEdit: true,
      hideInCreate: true,
      type: "dynamic",
      fields: [
        {
          identifier: "id",
          label: "ID",
          accessor: "id",
          inList: true,
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "whodunnit",
          accessor: "whodunnit",
          label: "whodunnit",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "event",
          accessor: "event",
          label: "event",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "changeset",
          accessor: "changeset",
          component: ChangesetField,
          label: "changeset",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
      ],
    },
  ],
  filters: [
    {
      label: { en: "Product Sector", ar: "قطاع المنتج" },
      name: "product_sector_id",
      type: "select",
      multiple: true,
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "productSector",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Brand", ar: "الماركة" },
      name: "brand_id",
      type: "select",
      multiple: true,
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "brand",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Start price", ar: "السعر المبدئي" },
      name: "start_price",
      type: "number",
      accessor: "start_price",
    },
    {
      label: { en: "End price", ar: "السعر النهائي" },
      name: "end_price",
      type: "number",
      accessor: "end_price",
    },
    {
      label: { en: "Search", ar: "بحث" },
      name: "query",
      type: "searchInput",
      // fieldIdentifier: "name_en",
    },
  ],
});
