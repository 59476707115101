import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "faqCategory",
  pluralName: "faqCategories",
  pathName: "faq_categories",
  endpoint: "/admins/faq_categories",
  serverName: "faq_category",
  serverPluralName: "faq_categories",
  displayName: { en: "FAQs Categories", ar: "فئات الأسئلة الشائعة" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  titleField: "name",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, marketingOfficer],
    edit: [superAdmin, applicationSupport, marketingOfficer],
    delete: [superAdmin, applicationSupport],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم بالانجليزية" },
      accessor: "name_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم بالعربية" },
      accessor: "name_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "name_de",
      label: { en: "German Name", ar: "الاسم الألماني" },
      accessor: "name_de",
      inList: false,
      type: "string",
      required: true,
    },
  ],
});
