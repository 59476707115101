import { Entity } from "@thewebops/admin-panel";
import ChangesetField from "../components/ChangesetField";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
} = ROLES;

export default new Entity({
  name: "productItem",
  pluralName: "productItems",
  pathName: "product_items",
  endpoint: "/admins/product_items",
  serverName: "product_item",
  serverPluralName: "product_items",
  displayName: { en: "Product Items", ar: "رقم المنتج" },
  titleField: "barcode",
  entityIdentifier: "id",
  showSearch: true,
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
    ],
    create: [superAdmin],
    edit: [superAdmin],
    delete: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "barcode",
      label: { en: "Barcode", ar: "باركود" },
      accessor: "barcode",
      inList: true,
      type: "string",
    },
    {
      identifier: "points",
      label: { en: "Points", ar: "النقاط" },
      accessor: "points",
      type: "number",
    },
    {
      identifier: "status",
      label: { en: "Status", ar: "الحالة" },
      accessor: "status",
      inList: true,
      type: "string",
      useHumanizer: true,
    },
    {
      identifier: "points_status",
      label: { en: "Points status", ar: "حالة النقاط" },
      accessor: "points_status",
      inList: true,
      type: "string",
      useHumanizer: true,
    },
    {
      identifier: "product_id",
      label: { en: "Product", ar: "المنتج" },
      formAccessor: (dataObject) => dataObject.product?.id,
      accessor: (dataObject) => dataObject.product?.name,
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "product",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "product_article_number",
      label: { en: "Article number", ar: "رقم المقالة" },
      formAccessor: (dataObject) => dataObject.product?.id,
      accessor: (dataObject) => dataObject.product?.article_number,
      inList: true,
      type: "reference",
      optionsEntity: {
        name: "product",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
      },
    },
    {
      identifier: "paper_trail/versions",
      accessor: (dataObject) => (dataObject.versions ? dataObject.versions["paper_trail/versions"] : null),
      formAccessor: (dataObject) => (dataObject.versions ? dataObject.versions["paper_trail/versions"] : null),
      label: "History",
      hideInShow: false,
      hideInEdit: true,
      hideInCreate: true,
      type: "dynamic",
      fields: [
        {
          identifier: "id",
          label: "ID",
          accessor: "id",
          inList: true,
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "whodunnit",
          accessor: "whodunnit",
          label: "whodunnit",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "event",
          accessor: "event",
          label: "event",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
        {
          identifier: "changeset",
          accessor: "changeset",
          component: ChangesetField,
          label: "changeset",
          usedToShow: true,
          hideInCreate: true,
          hideInEdit: true,
        },
      ],
    },
    {
      identifier: "last_order",
      label: "SAP Order Number",
      accessor: (dataObject) => dataObject.last_order?.sap_number,
      inList: false,
      type: "string",
      hideInCreate: true,
      hideInEdit: true,
    },
  ],
  filters: [
    {
      label: { en: "Barcode", ar: "باركود" },
      name: "query",
      type: "searchInput",
      fieldIdentifier: "barcode",
    },
    {
      label: { en: "Product", ar: "المنتج" },
      name: "product_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "product",
          labelField: "article_number",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Status", ar: "الحالة" },
      name: "status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "printed", label: "Printed" },
          { value: "available_for_sale", label: "Available For Sale" },
          { value: "sold", label: "Sold" },
        ],
      },
    },
    {
      label: { en: "Points status", ar: "حالة النقاط" },
      name: "points_status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "available_for_redemption", label: { en: "Available for redemption", ar: "متاح للإستبدال" } },
          { value: "fully_canceled", label: { en: "Fully Canceled", ar: "ملغى بالكامل" } },
          { value: "earned", label: { en: "Earned", ar: "مكتسب" } },
        ],
      },
    },
  ],
});
