import actions from "./actions";

const reducers = {
  [actions.exportBarcodes.request]: (state, action) => {
    state.errors = {};
    state.loading = true;
  },
  [actions.exportBarcodes.success]: (state, action) => {
    state.loading = false;
  },
  [actions.exportBarcodes.failure]: (state, action) => {
    state.errors = action.payload || { message: "there was an error" };
    state.loading = false;
  },
  [actions.downloadFile.request]: (state, action) => {
    state.errors = {};
    state.loading = true;
  },
  [actions.downloadFile.success]: (state, action) => {
    state.loading = false;
  },
  [actions.downloadFile.failure]: (state, action) => {
    state.errors = action.payload || { message: "there was an error" };
    state.loading = false;
  },
};

export default reducers;
