import actions from "./actions";

const reducers = {
  [actions.downloadImportTemplate.request]: (state, action) => {
    state.loading = true;
  },
  [actions.downloadImportTemplate.success]: (state, action) => {
    state.loading = false;
  },
  [actions.downloadImportTemplate.failure]: (state, action) => {
    state.loading = false;
  },
};

export default reducers;
