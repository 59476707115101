import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "draftOrderStatusEvent",
  pluralName: "draftOrderStatusEvents",
  pathName: "draft_order_status_events",
  endpoint: "/admins/draft_orders/status_events",
  serverName: "event",
  serverPluralName: "events",
  displayName: "draft order status events",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  isPassive: true,
});
