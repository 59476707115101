import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "propertyType",
  pluralName: "propertyTypes",
  pathName: "property_types",
  endpoint: "/admins/property_types",
  serverName: "property_type",
  serverPluralName: "property_types",
  displayName: { en: "Property Types", ar: "أنواع العقار" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  titleField: "title",
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, masterDataOwner],
    edit: [superAdmin, applicationSupport, masterDataOwner],
    delete: [superAdmin, applicationSupport],
  },
  customNotifications: {
    delete: {
      success: {
        title: "Deleted",
        message: "Record Deleted",
        isSticky: true,
      },
      error: {
        title: { en: "Fail", ar: "خطأ" },
        message: { en: "Record is associated with jobs", ar: "السجل مرتبط ببعض الوظائف" },
        isSticky: true,
      },
    },
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "title",
      label: { en: "Title", ar: "الاسم" },
      accessor: "title",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      hideInShow: true,
    },
    {
      identifier: "title_en",
      label: { en: "English Title", ar: " العنوان بالانجليزية" },
      accessor: "title_en",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "title_ar",
      label: { en: "Arabic Title", ar: "العنوان بالعربية" },
      accessor: "title_ar",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "title_de",
      label: { en: "Deutsch Title", ar: "العنوان الألماني" },
      accessor: "title_de",
      inList: false,
      type: "string",
      required: true,
    },
  ],
});
