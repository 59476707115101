import React, { useState, useEffect, useCallback, useMemo, Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Table } from "antd";
import PointCell from "./components/PointCell";
import { entitiesActions, getEntity, Loadable } from "@thewebops/admin-panel";
import { ButtonWrapper, GiftCellWrapper } from "./components/styled";
import { useTranslation } from "react-i18next";

const PointTierMatrix = ({ loading, entity }) => {
  const { t } = useTranslation("datatable");
  const pointsData = useSelector((state) => getEntity(state, entity.pluralName))?.data;
  const [pointTierCols, setPointTierCols] = useState({});
  const dispatch = useDispatch();
  const updatedCells = useRef({ points_tier_gifts_attributes: {} });

  const [tableValues, setTableValues] = useState([]);

  const constructCols = useCallback(() => {
    const constructedPointTierCols = {};
    const constructedGiftCols = {};
    const points = {};
    pointsData.map((element) => {
      constructedPointTierCols[element.points_tier.id] = element.points_tier.name;
      constructedGiftCols[element.gift.id] = element.gift.name;
      points[`${element.points_tier.id}${element.gift.id}`] = { points: element.points, id: element.id };
    });
    setPointTierCols(constructedPointTierCols);
    return { constructedGiftCols, constructedPointTierCols, points };
  }, [pointsData]);

  const constructMatrix = useCallback(() => {
    if (pointsData && pointsData.length > 0) {
      const { constructedGiftCols, constructedPointTierCols, points } = constructCols();
      const constructedTableValues = [];
      Object.keys(constructedGiftCols).map((element, index) => {
        const newEntry = {};
        newEntry.key = index;
        newEntry.giftId = element;
        newEntry.giftName = constructedGiftCols[element];
        Object.keys(constructedPointTierCols).map((pointTier) => {
          newEntry[`${pointTier}`] = points[`${pointTier}${element}`];
        });
        constructedTableValues.push(newEntry);
      });
      setTableValues(constructedTableValues);
    }
  }, [pointsData]);

  useEffect(() => {
    constructMatrix();
  }, [constructMatrix]);

  const prepareUpdatedCells = () => {
    let payload = [];
    const { points_tier_gifts_attributes } = updatedCells.current;
    const keys = Object.keys(points_tier_gifts_attributes);

    payload =
      keys.length > 0 &&
      keys.reduce((acc, current) => {
        acc.push({ id: current, points: points_tier_gifts_attributes[current] });
        return acc;
      }, []);
    return { points_tier_gifts_attributes: payload || [] };
  };

  const onSave = (e) => {
    e.preventDefault();
    dispatch(
      entitiesActions[entity.name].pointsTierGifts.request({
        entity,
        data: { points_tier_gift: prepareUpdatedCells() },
      }),
    );
    updatedCells.current = { points_tier_gifts_attributes: {} };
  };

  const onChangeCell = ({ id, points }) => {
    const { points_tier_gifts_attributes } = updatedCells.current;
    points_tier_gifts_attributes[id] = points;
    updatedCells.current.points_tier_gifts_attributes = points_tier_gifts_attributes;
  };

  const Matrix = () => (
    <Fragment>
      <Table
        scroll={{ x: 100 }}
        dataSource={tableValues}
        pagination={false}
        rowKey="pricing"
        locale={{ emptyText: t("noData") }}
      >
        <Table.Column
          title="Gifts / Point Tiers"
          align="start"
          render={(_currentValue, record) => <GiftCellWrapper>{record.giftName}</GiftCellWrapper>}
        />
        {Object.keys(pointTierCols).map((pointTier, index) => {
          return (
            <Table.Column
              key={index}
              title={pointTierCols[pointTier]}
              dataIndex={pointTier}
              align="center"
              render={(_currentValue, record) => {
                return <PointCell value={record[pointTier]} onChange={onChangeCell} />;
              }}
            />
          );
        })}
      </Table>
      <ButtonWrapper>
        <Button onClick={onSave}>{t("datatable:save")}</Button>
      </ButtonWrapper>
    </Fragment>
  );

  return <Loadable render={() => <Matrix />} loading={loading} />;
};

export default PointTierMatrix;
