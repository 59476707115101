import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  marketingOfficer,
  callCenterSupervisor,
  callCenterAgent,
  salesRep,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
  purchaser,
  afterSalesSectionHead,
  technicalSales,
} = ROLES;

export default new Entity({
  name: "productSector",
  pluralName: "productSectors",
  pathName: "product_sectors",
  endpoint: "/admins/product_sectors",
  serverName: "product_sector",
  serverPluralName: "product_sectors",
  displayName: { en: "Product Sectors", ar: "قطاعات المنتج" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  titleField: "name",
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      marketingOfficer,
      callCenterSupervisor,
      callCenterAgent,
      salesRep,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
      purchaser,
      afterSalesSectionHead,
      technicalSales,
    ],
    create: [superAdmin, applicationSupport, crSectionHead, masterDataOwner],
    edit: [superAdmin, applicationSupport, crSectionHead, masterDataOwner],
    delete: [superAdmin, applicationSupport, masterDataOwner],
  },
  customNotifications: {
    delete: {
      error: {
        title: { en: "Not Allowed", ar: "غير مسموح" },
        message: (sector) => sector.base,
      },
    },
  },
  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
  ],
});
