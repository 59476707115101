import { Entity } from "@thewebops/admin-panel";
import actions from "./redux/actions";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import { ROLES } from "utils/constants";

const {
  superAdmin,
  applicationSupport,
  callCenterAgent,
  callCenterSupervisor,
  crSpecialist,
  crSectionHead,
  crWarehouse,
  warehouseClerk,
  masterDataOwner,
  accounant,
} = ROLES;

export default new Entity({
  name: "order",
  pluralName: "orders",
  pathName: "order",
  endpoint: "/admins/orders",
  serverName: "order",
  serverPluralName: "orders",
  displayName: { en: "Orders", ar: "الطلبات" },
  customSagas: sagas,
  customReducers: reducers,
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  customActions: actions,
  titleField: "sap_number",
  showSearch: true,
  authorizedRoles: {
    entity: [
      superAdmin,
      applicationSupport,
      callCenterAgent,
      applicationSupport,
      callCenterSupervisor,
      crSpecialist,
      crSectionHead,
      crWarehouse,
      warehouseClerk,
      masterDataOwner,
      accounant,
    ],
    create: [superAdmin],
    edit: [superAdmin],
    delete: [superAdmin],
  },
  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "sap_number",
      label: { en: "SAP Number", ar: "رقم ساب" },
      accessor: "sap_number",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "client_id",
      label: { en: "Client Name", ar: "اسم العميل" },
      formAccessor: (dataObject) => dataObject.client?.id,
      accessor: (dataObject) => dataObject.client?.name,
      inList: true,
      hideInEdit: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "client",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "printing_status",
      label: { en: "Printing Status", ar: "حالة الطباعة" },
      accessor: "printing_status",
      inList: true,
      hideInEdit: true,
      type: "select",
      options: [
        {value: "printing", label: {en: "printing", ar: "جاري الطباعة"}},
        {value: "printed", label: {en: "printed", ar: "مطبوعة"}},
        {value: "not_printed", label: {en: "not printed", ar: "لم تتم الطباعة"}}
      ],
      useHumanizer: true,
    },
    {
      identifier: "branch_retailer",
      label: { en: "Retailer Branch", ar: "فرع البيع" },
      accessor: "branch_retailer",
      type: "string",
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "order_type",
      label: { en: "Order Type", ar: "نوع الطلب" },
      accessor: "order_type",
      type: "select",
      options: [
        {value: "normal", label: {en: "normal", ar: "عادي"}},
        {value: "refund", label: {en: "refund", ar: "مرتجع"}},
      ],
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "sap_order_type",
      label: { en: "SAP Order Type", ar: "نوع طلب الساب" },
      accessor: "sap_order_type",
      type: "string",
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "created_on",
      label: { en: "Created At", ar: "تاريخ الطلب" },
      accessor: "created_on",
      inList: true,
      hideInEdit: true,
      type: "datetime",
    },
    {
      identifier: "new_barcodes",
      label: {en: "New Barcodes", ar: "باركودز جديدة"},
      accessor: "new_barcodes",
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "total_amount",
      label: {en: "Total Amount", ar: "إجمالي الكمية"},
      accessor: "total_amount",
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "product_items_count",
      label: {en: "Product Items Count", ar: "عدد قطع المنتجات"},
      accessor: "product_items_count",
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "not_redeemable_product_items",
      label: {en: "Not redeemable product items", ar: "قطع المنتجات الغير القابلة للاسترداد"},
      accessor: "not_redeemable_product_items",
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "products",
      label: {en: "Products", ar: "المنتجات"},
      accessor: (dataObject) => dataObject.products,
      type: "table",
      lgCol: 12,
      mdCol: 12,
      fields: [
        { identifier: "id", label: {en: "ID", ar: "الرقم التعريفي"}, accessor: "id", inList: true },
        { identifier: "article_number", label: {en: "Article #", ar: "رقم المنتج"}, accessor: "article_number", type: "string", inList: true },
        { identifier: "name", label: {en: "Name", ar: "الاسم"}, accessor: "name", type: "string", inList: true },
        {
          identifier: "new_barcodes",
          label: {en: "New Barcodes", ar: "باركودز جديدة"},
          accessor: "new_barcodes",
          type: "string",
          inList: (dataObject) => dataObject.order_type === "normal",
        },
        { identifier: "amount", label: {en: "Total Amount", ar: "إجمالي الكمية"}, accessor: "amount", type: "string", inList: true },
        {
          identifier: "product_items_count",
          label: {en: "Product Items Count", ar: "عدد قطع المنتجات"},
          accessor: "product_items_count",
          type: "string",
          inList: true,
        },
        {
          identifier: "not_redeemable_product_items",
          label: {en: "Not redeemable barcodes", ar: "باركودز غير قابلة للاسترداد"},
          accessor: "not_redeemable_product_items",
          type: "string",
          inList: true,
        },
      ],
      tableConfig: {
        name: "order",
        type: "static",
        import: undefined,
        showRowActions: true,
        rowActions: [
          {
            label: {en: "Export Barcodes", ar: "استخراج البادكودز"},
            name: "exportBarcodes",
            authorizedRoles: [superAdmin, crWarehouse, applicationSupport],
          },
          {
            label: {en: "Download File", ar: "تحميل الملف"},
            name: "downloadFile",
            authorizedRoles: [superAdmin, crWarehouse, warehouseClerk, applicationSupport],
          },
        ],
      },
    },
  ],
  filters: [
    {
      name: "printing_status",
      label: { en: "Printing Status", ar: "حالة الطباعة" },
      accessor: "printing_status",
      inList: true,
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "not_printed", label: { en: "Not Printed", ar: "غير مطبوعة" } },
          { value: "printing", label: { en: "Printing", ar: "طباعة" } },
          { value: "printed", label: { en: "Printed", ar: "مطبوعة " } },
        ],
      },
      usedToShow: true,
    },

    {
      label: { en: "SAP Number", ar: "رقم ساب" },
      name: "sap_number",
      type: "select",
      accessor: "sap_number",
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "order",
          labelField: "sap_number",
          valueField: "sap_number",
        },
      },
    },
    {
      label: { en: "Order Type", ar: "نوع الطلب" },
      name: "order_type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "normal", label: { en: "Normal", ar: "عادي" } },
          { value: "refund", label: { en: "Refund", ar: "استرجاع" } },
        ],
      },
    },
  ],
});
