import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const {
  superAdmin, applicationSupport, marketingOfficer, callCenterSupervisor, callCenterAgent, salesRep,
  crSpecialist,crSectionHead, crWarehouse, warehouseClerk, masterDataOwner, accounant,
  purchaser, afterSalesSectionHead,technicalSales
} = ROLES;

export default new Entity({
  name: "draftProduct",
  pluralName: "draftProducts",
  pathName: "draftProduct",
  serverName: "draftProduct",
  serverPluralName: "draftProducts",
  displayName: "Draft Product",
  // titleField: "name",
  // entityIdentifier: "id",
  //isPassive: true,
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "new_barcodes",
      label: "New Barcodes",
      accessor: "new_barcodes",
      type: "string",
    },
    // {
    //   identifier: "total_amount",
    //   label: "Total Amount",
    //   accessor: "total_amount",
    //   type: "string",
    // },
    // {
    //   identifier: "product_items_count",
    //   label: "Product Items Count",
    //   accessor: "product_items_count",
    //   type: "string",
    // },
    // {
    //   identifier: "not_redeemable_product_items",
    //   label: "Not redeemable product items",
    //   accessor: "not_redeemable_product_items",
    //   type: "string",
    // },
  ],
});
